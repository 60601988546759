import React, { useState } from "react";
import Notiflix, { Loading } from "notiflix";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import logo from "../../../img/ans-logo.png";
import bg from "../../../img/cover.jpg";

import { LOGIN_USER } from "../apollo";
import { USER_KEY } from "../../../helper";
import { loadingScreen, messageError, messageWarning } from "../../../helper/";

export default function Login() {
  const [loginUser] = useMutation(LOGIN_USER);

  return (
    <body>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) errors.email = "ກະລຸນາປ້ອນອີເມວ";
          if (!values.password) errors.password = "ກະລຸນາປ້ອນລະຫັດຜ່ານ";
          return errors;
        }}
        onSubmit={async (values) => {
          try {
            let user = await loginUser({
              variables: {
                data: {
                  email: String(values?.email),
                  password: String(values?.password),
                },
              },
            });
            if (user) {
              localStorage.setItem(USER_KEY, user?.data?.userLogin.accessToken);
              loadingScreen();
              setTimeout(() => {
                Loading.remove();
                window.location.href = `/home`;
              }, 2000);
            } else {
              messageWarning("ອີເມວ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ");
            }
          } catch (error) {
            messageError("ອີເມວ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          isDisabled,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="wrapper">
              <section className="login-content">
                <div className="row m-0 align-items-center bg-white h-100">
                  <div className="col-12 col-lg-6">
                    <div className="row justify-content-center">
                      <div className="col-md-10">
                        <div className="card card-transparent shadow-none d-flex justify-content-center mb-0 auth-card iq-auth-form">
                          <div className="card-body">
                            <center>
                              <img
                                src={logo}
                                alt="logo"
                                className="img-fluid mb-3"
                                width={200}
                              />
                            </center>
                            {/* <h2 className="mb-2 text-center">
                              ລະບົບສະປາ
                            </h2> */}
                            <div className="row mt-5">
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label htmlFor="email" className="form-label">
                                    ອີເມວ
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control form-control-lg"
                                    name="email"
                                    placeholder="ອີເມວ"
                                    onChange={handleChange}
                                  />
                                  {errors.email && touched.email && (
                                    <div className="text-danger">
                                      {errors.email}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label
                                    htmlFor="password"
                                    className="form-label"
                                  >
                                    ລະຫັດຜ່ານ
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control form-control-lg"
                                    placeholder="ລະຫັດຜ່ານ"
                                    name="password"
                                    onChange={handleChange}
                                  />
                                  {errors.password && touched.password && (
                                    <div className="text-danger">
                                      {errors.password}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center">
                              <button
                                type="submit"
                                handleSubmit={handleSubmit}
                                disabled={isDisabled}
                                className="btn btn-primary btn-lg fs-3 w-100 hvr-shutter-out-horizontal"
                              >
                                ເຂົ້າສູ່ລະບົບ{" "}
                                <i className="icon-login float-end mt-2" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 p-0 vh-100 overflow-hidden">
                    <img
                      src={bg}
                      className="img-fluid"
                      alt="images"
                      loading="lazy"
                      style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                  </div>
                </div>
              </section>
            </div>
          </form>
        )}
      </Formik>
    </body>
  );
}
