import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { placeholderImage, url_path_files, url_path_images } from ".";
export default function PreviewFiles({ image, width, height }) {
  const [show, setShow] = useState(false);
  return (
    <React.Fragment>
      <div className="avatar sm">
        {image ? (
          <>
            <button className="btn btn-secondary"
            onClick={() => setShow(true)}
            >ເບີ່ງ CV</button>
          </>
        ) : (
         "ບໍ່ມີໄຟລ໌ເອກະສານ"
        )}
      </div>
      <Modal show={show} centered scrollable size="lg" onHide={() => setShow(false)} className="text-center text-primary">
        <Modal.Header closeButton={() => setShow(false)}>
          <Modal.Title className="fs-5">
            <i className="icon-list" /> ເບິ່ງລາຍລະອຽດເອກະສານ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body text-center text-primary" style={{ backgroundColor: "transparent" }}>
          <img
            src={image ? url_path_files + image : placeholderImage(width, height)}
            className="img-fluid w-100"
            alt=""
            style={{ minHeight: height, minWidth: width }}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
