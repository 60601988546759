import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { USER_KEY } from "../helper";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../Screens/login/components/Login";
import CustomSideNav from "../Layout/SideNav";
import * as ROUTES from "./app";
import HomePage from "../Screens/home";
import ListUsers from "../Screens/users";
import NavBar from "../Layout/Navbar";
import CustomSetting from "./CustomSetting";
import MemberScreen from "../Screens/members";
import CatalogScreen from "../Screens/catalogs";
import SlideImageScreen from "../Screens/slideImage";
import CommunityScreen from "../Screens/community";
import VideoScreen from "../Screens/videos";
import Register from "../Screens/register";
import SummaryScore from "../summaryScore";
import FeedBackBranch from "../Screens/feedBackBranch";
import Employees_votes from "../Screens/employees_vote";

function Routes() {
  const _local = localStorage.getItem(USER_KEY);
  return (
    <Router>
      <Switch>
        {!_local ? (
          <React.Fragment>
            <Route path="/">
              <Redirect to="/login" />
            </Route>
            <PublicRoute exact path="/login" component={Login} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Route
              render={({ location, history }) => (
                <React.Fragment>
                  <NavBar />
                  <Route exact path="/login">
                    <Redirect to="/home" />
                  </Route>
                  <Route exact path="/">
                    <Redirect to="/home" />
                  </Route>
                  <CustomSideNav location={location} history={history} />
                  <main className="main-content mt-5 pt-5">
                    <PrivateRoute
                      path={ROUTES.HOME_PAGE}
                      exact
                      component={(props) => <HomePage />}
                    />
                    <PrivateRoute
                      path={ROUTES.LIST_USERS}
                      exact
                      component={(props) => <ListUsers />}
                    />
                    <PrivateRoute
                      path={ROUTES.LIST_MEMBER}
                      exact
                      component={(props) => <MemberScreen />}
                    />
                    <PrivateRoute
                      path={ROUTES.LIST_CATEGORIES}
                      exact
                      component={(props) => <CatalogScreen />}
                    />
                    <PrivateRoute
                      path={ROUTES.LIST_SLIDE_IMAGE}
                      exact
                      component={(props) => <SlideImageScreen />}
                    />
                    <PrivateRoute
                      path={ROUTES.LIST_COMMUNITY}
                      exact
                      component={(props) => <CommunityScreen />}
                    />
                    <PrivateRoute
                      path={ROUTES.LIST_VIDEOS}
                      exact
                      component={(props) => <VideoScreen />}
                    />
                     <PrivateRoute
                      path={ROUTES.REGISTER}
                      exact
                      component={(props) => <Register />}
                    />
                    <PrivateRoute
                      path={ROUTES.REPORT_FEEDBACK}
                      exact
                      component={(props) => <FeedBackBranch />}
                    />
                     <PrivateRoute
                      path={ROUTES.SUMMARY_SCORE}
                      exact
                      component={(props) => <SummaryScore />}
                    />
                    <PrivateRoute
                    path={ROUTES.EMPLOYEE_SCORE_VOTES}
                    exact
                    component={(props) => <Employees_votes />}
                    />
                  </main>
                  {/* setting */}
                  <CustomSetting />
                </React.Fragment>
              )}
            />
          </React.Fragment>
        )}
      </Switch>
    </Router>
  );
}

export default Routes;
