import React, { useEffect, useState } from "react";
import { QUERY_TOTALS } from "./apollo";
import { useLazyQuery } from "@apollo/client";
import { FormSelect } from "react-bootstrap/esm";
import SelectBranch from "../controllers/SelectBranch";
//import { REPORT_FEEDBACK } from "../Routes/app";
import useReactRouter from "use-react-router";

export default function SummaryScore() {
  //const { history } = useReactRouter();
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [listBranch, setListBranch] = useState();
  const [items, setItems] = useState();
  const [query_totals, { data }] = useLazyQuery(QUERY_TOTALS, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    query_totals({
      variables: {
        where: {
          full_year: selectedYear,
          branch: listBranch?.id_branch
            ? parseInt(listBranch?.id_branch)
            : undefined,
        },
      },
    });
  }, [query_totals, listBranch, selectedYear]);

  useEffect(() => {
    setItems(data?.commentSummary?.dataSummary || []);
  }, [data]);

  const years = Array.from(
    { length: currentYear - 2023 + 1 },
    (_, index) => 2023 + index
  );
  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <div className="row">
          <div className="col-md-6">
            <SelectBranch
              value={listBranch?.id_branch}
              onChange={(obj) => {
                setListBranch(obj);
              }}
            />
          </div>
          <div className="col-md-3" style={{ marginTop: -5 }}>
            <div>
              <FormSelect
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value, 10))}
                style={{ height: "40px" }}
              >
                {years.map((year) => (
                  <option
                    key={year}
                    value={year}
                    style={{ color: year === currentYear ? "red" : "black" }}
                  >
                    {year}
                  </option>
                ))}
              </FormSelect>
            </div>
          </div>
        </div>

        <div className="card-body mt-4">
          <div className="table-responsive card">
            <table className="table table-responsive mb-0 table-sm table-striped">
              <thead>
                <tr>
                  <th className="text-nowrap  text-center fs-5">#</th>
                  <th className="text-nowrap  text-left fs-5">ສາຂາ</th>
                  <th className="text-nowrap  text-center fs-5">
                    ຄະແນນທັ້ງໝົດ
                  </th>
                </tr>
              </thead>
              <tbody>
                {items?.map(
                  (values, index) => (
                    console.log(values),
                    (
                      <tr
                        key={index}
                        // onClick={() => history.push(`${REPORT_FEEDBACK}?${values?.branch}`)}
                      >
                        <td className="text-nowrap text-center text-dark fs-5">
                          {index + 1}
                        </td>
                        <td className="text-nowrap text-left text-dark fs-5">
                          {values?.branch_name}
                        </td>
                        <td className="text-nowrap text-center">
                          <b className="fs-3 text-success">{values?.Total}</b>
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
