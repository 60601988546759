import { gql } from "@apollo/client";

export const QUERY_BRANCH = gql`
query CommentBranchSchemas($where: CommentBranchSchemaWhereInput, $limit: Int, $skip: Int) {
    commentBranchSchemas(where: $where, limit: $limit, skip: $skip) {
      total
      data {
        _id
        branch {
          branch_name
        }
        description
        phoneNumber
        point
        createdAt
      }
    }
  }
`