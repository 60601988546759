import { gql } from "@apollo/client";

export const QUERY_TOTALS = gql`
  query DataSummary($where: CommentBranchSchemaWhereInput) {
    commentSummary(where: $where) {
      dataSummary {
        branch_name
        Total
      }
    }
  }
`;
