export const HOME_PAGE = "/home";
export const LIST_USERS = "/users";
export const LIST_MEMBER = "/members";
export const LIST_CATEGORIES = "/catalogs";
export const LIST_SLIDE_IMAGE = "/slide-images";
export const LIST_COMMUNITY = "/community";
export const LIST_VIDEOS = "/videos";
export const REGISTER = "/register";
export const REPORT_FEEDBACK = "/report_feedback";
export const SUMMARY_SCORE = "/summary_score";
export const EMPLOYEE_SCORE_VOTES = "/employee_score_votes";
