import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Select from "react-select";
import Schema from "./Schema";
export default function SelectBranch({ onChange, disabled, value, allBranch }) {
  const [items, setItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [fetchData, { data: data, loading: loading }] = useLazyQuery(
    Schema.SELECT_BRANCH,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          public: 1,
        },
        limit: 800,
      },
    });
  }, []);
  useEffect(() => {
    if (allBranch) {
      const results = data?.branches?.data || [];
      if (results?.length > 0) {
        const _results = results.map((item, index) => {
          const object = {
            ...item,
            value: item?.id_branch,
            label: index + 1 + "." + item?.branch_name,
          };
          return object;
        });
        setItems([{ value: "", label: "ທັງໝົດ" }, ..._results]);
      } else {
        setItems([]);
      }
    } else {
      const results = data?.branches?.data || [];
      if (results?.length > 0) {
        const _results = results.map((item, index) => {
          const object = {
            ...item,
            value: item?.id_branch,
            label: index + 1 + ".  " + item?.branch_name,
          };
          return object;
        });
        setItems(_results);
      } else {
        setItems([]);
      }
    }
  }, [data]);
  //set value
  useEffect(() => {
    if (value) {
      const result = items?.filter((item) => item?.id_branch === value);
      setSelectedOption(result[0] || null);
    } else {
      setSelectedOption(null);
    }
  }, [items, value]);

  return (
    <>
      <div style={{ minWidth: 400, color: "black", marginTop: -5 }}>
        <Select
          className="select-search"
          isDisabled={disabled}
          value={selectedOption}
          placeholder={loading ? "ກຳລັງໂຫຼດ..." : "ຄົ້ນຫາສາຂາ..."}
          onChange={(res) => {
            setSelectedOption(res);
            if (onChange) {
              onChange(res);
            }
          }}
          options={items}
        />
      </div>
    </>
  );
}
