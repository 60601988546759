import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as _ from "lodash";

import {
  messageSuccess,
  messageError,
  valiDate,
  placeholderImage,
  url_path_images,
  onLoading,
  USER_KEY,
  url_upload_images,
} from "../../helper";
import { UPDATE_CATALOG } from "./apollo";
import { Form, Modal, Spinner } from "react-bootstrap";
import compressImage from "../../helper/uploadFile";
import axios from "axios";
import { Notify } from "notiflix";
export default function EditCatalog({ item, onSuccess }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [image, setImage] = useState("");
  const [updateCatalog, { loading: adding }] = useMutation(UPDATE_CATALOG);

  useEffect(() => {
    if (item?.coverImage) {
      setImage(item?.coverImage);
    }
  }, [item]);

  const handleChangeFile = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        setImage(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res = await axios.post(url_upload_images, formData, {
          headers: {
            Authorization: localStorage.getItem(USER_KEY),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setImage(filename);
        } else {
          setImage("");
          Notify.messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  const { handleChange, errors, values, handleSubmit, resetForm, isDisabled } =
    useFormik({
      initialValues: {
        title: item?.title,
        url: item?.url,
        type: item?.type,
      },
      enableReinitialize: false,
      validate: (values) => {
        const errors = {};
        if (!values.title) {
          errors.title = "ກະລຸນາປ້ອນຫົວຂໍ້";
        }
        if (!values.type) {
          errors.type = "ກະລຸນາເລືອກປະເພດ";
        }
        return errors;
      },
      onSubmit: async (values) => {
        try {
          const { data: updated } = await updateCatalog({
            variables: {
              data: {
                title: String(values?.title),
                url: String(values?.url),
                type: String(values?.type),
                coverImage: String(image),
              },
              where: {
                id: parseInt(item?.id),
              },
            },
          });
          if (updated) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setShow(false);
            onSuccess(true);
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
        }
      },
    });
  return (
    <React.Fragment>
      <button
        className="btn btn-icon  btn-success rounded-circle me-2"
        onClick={() => setShow(true)}
      >
        <i className="icon-edit-3" />
      </button>
      <Modal
        centered
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        backdrop="static"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className="fs-5">
            <i className="icon-edit-3" /> ແກ້ໄຂຂໍ້ມູນໝວດໝູ່
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-4 col-md-12 col-sm-12">
              <div className="form-group">
                <div style={{ textAlign: "center" }}>
                  <input
                    type="file"
                    id="file-upload"
                    onChange={handleChangeFile}
                    accept="image/png, image/gif, image/jpeg"
                    hidden
                  />
                  <label for="file-upload">
                    <div
                      style={{
                        height: 200,
                        width: 200,
                        cursor: "pointer",
                        display: "flex",
                        backgroundImage: `url(${onLoading})`,
                        justifyContent: "center",
                        backgroundPosition: "center",
                        backgroundSize: "100%",
                        textAlign: "center",
                        borderRadius: 7,
                      }}
                    >
                      {image ? (
                        <img
                          src={image ? url_path_images + image : placeholderImage(200, 200)}
                          alt={image}
                          style={{
                            height: 200,
                            width: 200,
                            borderRadius: 7,
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundImage: `url(${placeholderImage(200, 200)})`,
                            display: "flex",
                            height: 200,
                            width: 200,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 7,
                            backgroundSize: "100%",
                          }}
                        ></div>
                      )}
                    </div>
                  </label>
                </div>
              </div>
              <center>ອັບໂຫຼດຮູບພາບ</center>
            </div>
            <div className="col-12 col-lg-8 col-md-12 col-sm-12">
              <div className="form-group">
                <label>ຫົວຂໍ້{valiDate()}</label>
                <input
                  name="title"
                  type="text"
                  placeholder="ຫົວຂໍ້"
                  onChange={handleChange}
                  className={
                    errors.title ? "form-control is-invalid" : "form-control"
                  }
                  value={values.title}
                />
                <i className="text-danger">{errors.title}</i>
              </div>
              <div className="form-group">
                <label>url</label>
                <input
                  name="url"
                  type="text"
                  placeholder="url"
                  onChange={handleChange}
                  className="form-control"
                  value={values?.url}
                />
              </div>
              <div className="form-group">
                <label>ປະເພດ{valiDate()}</label>
                <select
                  name="type"
                  className={
                    errors.type ? "form-control is-invalid" : "form-control"
                  }
                  onChange={handleChange}
                  value={values.type}
                >
                  <option value="">ເລືອກປະເພດ</option>
                  <option value="ACTIVITY">ACTIVITY</option>
                  <option value="NEWS">NEWS</option>
                  <option value="COMMUNITY">COMMUNITY</option>
                  <option value="PODCAST">PODCAST</option>
                </select>
                <i className="text-danger">{errors.type}</i>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-lg hvr-shutter-out-horizontal"
            onClick={() => handleSubmit()}
            disabled={isDisabled}
          >
            <i className="icon-check-circle me-1" />
            ບັນທຶກ
          </button>
          <button
            type="button"
            className="btn btn-light btn-lg hvr-shutter-out-horizontal"
            onClick={() => handleClose()}
          >
            <i className="icon-x me-2" />
            ຍົກເລີກ
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
