import { gql } from "apollo-boost";

export const QUERY_MEMBERS = gql`
 query Members($where: MemberWhereInput, $limit: Int, $skip: Int, $isSummary: Boolean) {
  members(where: $where, limit: $limit, skip: $skip, isSummary: $isSummary) {
    total
    totalGeneralMember
    totalShop
    totalVipMember
    data {
      id
      profileImage
      fullName
      phoneNumber
      email
      password
      isPublic
      description
      level
      isDeleted
      createdAt
      updatedAt
    }
  }
}
`;
export const CREATE_MEMBER = gql`
mutation CreateMember($data: MemberInput!) {
  createMember(data: $data) {
    data
    status
  }
}
`;

export const UPDATE_MEMBER = gql`
mutation UpdateMember($data: MemberInput!, $where: WhereById!) {
  updateMember(data: $data, where: $where) {
    data
    status
  }
}
`;

export const DELETE_MEMBER = gql`
mutation DeleteMember($where: WhereById!) {
  deleteMember(where: $where) {
    data
    status
  }
}
`;
