import React, { useEffect, useState } from "react";
import { currency } from "../helper";
export default function LimitData({
  numberRows,
  onChangeRows,
  onSearch,
  numberPage,
  total,
  value,
  onChange,
  returnValue,
  hiddenSearch,
  col,
}) {
  const [_onSearches, setOnSearch] = useState("`");
  useEffect(() => {
    if (_onSearches === "") {
      onSearch(_onSearches);
    }
  }, [_onSearches]);

  function _onSearch() {
    onSearch(_onSearches);
  }
  const _handleKeypress = (e) => {
    if (e.key === "Enter") {
      _onSearch();
    }
  };

  return (
    <>
      <div className="dropdown me-2 pt-3">
        <div
        // style={{fontSize: "14px"}}
        >
          <p>
            {" "}
            ສະເເດງ {numberRows * numberPage - numberRows + 1} {"-"}{" "}
            {numberRows * numberPage} ລາຍການ ໃນຂໍ້ມູນທັງໜົດ{" "}
            {currency(total ?? "")} ລາຍການ{" "}
          </p>
        </div>
      </div>
      <div className="dropdown me-2">
        <div className="input-group">
          <input
            type="search"
            className="form-control"
            placeholder="ຄົ້ນຫາ..."
            onChange={(e) => {
               setOnSearch(e.target.value);
            }}
            defaultValue={returnValue}
            onKeyPress={_handleKeypress}
          />
          <button
            type="button"
            onClick={() => _onSearch()}
            className="btn btn-primary btn-sm ml-2"
          >
            <i className="icon-search1" /> ຄົ້ນຫາ
          </button>
        </div>
      </div>
      <div className="dropdown me-2">
        <select
          id="sel-rows"
          className="form-control"
          onChange={(e) => {
            onChangeRows(e);
          }}
          selected={numberRows}
        >
          <option
            value="10"
            selected={Number(numberRows) === 10 ? true : false}
          >
            10 ລາຍການ
          </option>
          <option
            value="20"
            selected={Number(numberRows) === 20 ? true : false}
          >
            20 ລາຍການ
          </option>
          <option
            value="50"
            selected={Number(numberRows) === 50 ? true : false}
          >
            50 ລາຍການ
          </option>
          <option
            value="100"
            selected={Number(numberRows) === 100 ? true : false}
          >
            100 ລາຍການ
          </option>
        </select>
      </div>
    </>
  );
}
