import React, { useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import * as _ from "lodash";
import {
  messageSuccess,
  messageError,
  valiDate,
  url_path_images,
  addSlashes,
  USER_KEY,
  onLoading,
  placeholderImage,
  url_upload_images,
} from "../../helper";
import { CREATE_COMMUNITY } from "./apollo";
import { Form, Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import { Notify } from "notiflix";
import ListCatalogs from "../../helper/componentsList/SelectCatalogs";
import compressImage from "../../helper/uploadFile";
export default function AddCommunity({ setNewLoad }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [crateCommunity] = useMutation(CREATE_COMMUNITY);
  const [catalogData, setCatalogData] = useState({});
  const [image, setImage] = useState("");


  const [editorStateDescription, setEditorStateDescription] = useState(
    EditorState.createEmpty()
  );

  const description = draftToHtml(
    convertToRaw(editorStateDescription.getCurrentContent())
  );

  const handleChangeImage = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        setImage(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res = await axios.post(url_upload_images, formData, {
          headers: {
            Authorization: localStorage.getItem(USER_KEY),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setImage(filename);
        } else {
          setImage("");
          Notify.messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  const { handleChange, errors, values, handleSubmit, resetForm, isDisabled } =
    useFormik({
      initialValues: {
        title: "",
        description: "",
      },
      enableReinitialize: false,
      validate: (values) => {
        const errors = {};
        if (!catalogData?.id) {
          errors.catalog = "ກະລຸນາເລືອກໝວດໝູ່";
        }
        if (!values.title) {
          errors.title = "ກະລຸນາປ້ອນຫົວຂໍ້ກ່ອນ";
        }
        return errors;
      },
      onSubmit: async (values) => {
        try {
          const _arrayDescription = Array.from(description);
          const withEmojis = /\p{Emoji}/u;
          const _convertDescription = _arrayDescription?.map((char) => {
            if (withEmojis.test(char)) {
              return "&#x" + char.codePointAt(0).toString(16) + ";";
            } else {
              return char;
            }
          });
          const { data: updated } = await crateCommunity({
            variables: {
              data: {
                catalog: parseInt(catalogData?.id),
                title: String(values.title),
                description: addSlashes(_convertDescription.join("")),
                image: String(image),
              },
            },
          });
          if (updated) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setShow(false);
            setNewLoad(true);
            setImage("");
            resetForm();
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
        }
      },
    });
  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => setShow(true)}
      >
        <i className="icon-plus me-1" />
        ເພີ່ມກິດຈະກຳ ແລະ ຂ່າວສານ
      </button>
      <Modal
        centered
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">
            <i className="icon-plus me-1" /> ເພີ່ມກິດຈະກຳ ແລະ ຂ່າວສານ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-4 col-md-12 col-sm-12">
              <div className="form-group">
                <div style={{ textAlign: "center" }}>
                  <input
                    type="file"
                    id="image-upload"
                    onChange={handleChangeImage}
                    accept="image/png, image/gif, image/jpeg"
                    hidden
                  />
                  <label for="image-upload">
                    <div
                      style={{
                        height: 350,
                        width: 350,
                        cursor: "pointer",
                        display: "flex",
                        backgroundImage: `url(${onLoading})`,
                        justifyContent: "center",
                        backgroundPosition: "center",
                        backgroundSize: "100%",
                        textAlign: "center",
                        borderRadius: 7,
                      }}
                    >
                      {image ? (
                        <img
                          src={
                            image
                              ? url_path_images + image
                              : placeholderImage(350, 350)
                          }
                          alt={image}
                          style={{
                            height: 350,
                            width: 350,
                            borderRadius: 7,
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundImage: `url(${placeholderImage(
                              350,
                              350
                            )})`,
                            display: "flex",
                            height: 350,
                            width: 350,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 7,
                            backgroundSize: "100%",
                          }}
                        ></div>
                      )}
                    </div>
                  </label>
                </div>
              </div>
              <center>ອັບໂຫຼດຮູບພາບ</center>
            </div>
            <div className="col-12 col-lg-8 col-md-12 col-sm-12">
              <div className="form-group">
                <label>ຫົວຂໍ້{valiDate()}</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="title"
                  onChange={handleChange}
                  value={values.title}
                />
                <i className="text-danger">{errors.title}</i>
              </div>
              <div className="form-group">
                <label>ໝວດໝູ່{valiDate()}</label>
                <ListCatalogs
                  className={""}
                  style={""}
                  onChange={(res) => {
                    setCatalogData(res);
                  }}
                  value={catalogData?.id}
                />
                <i className="text-danger">{errors.catalog}</i>
              </div>
              <div className="form-group">
                <label>ລາຍລະອຽດ</label>
                <div
                  className="w-100"
                  style={{
                    minHeight: 400,
                    border: "1px solid #ccc",
                    borderRadius: 5,
                  }}
                >
                  <Editor
                    editorState={editorStateDescription}
                    rows={5}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="px-3 custom-editor-content"
                    onEditorStateChange={setEditorStateDescription}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-lg hvr-shutter-out-horizontal"
            onClick={() => handleSubmit()}
            disabled={isDisabled}
          >
            <i className="icon-check-circle me-1" />
            ບັນທຶກ
          </button>
          <button
            type="button"
            className="btn btn-light btn-lg hvr-shutter-out-horizontal"
            onClick={() => handleClose()}
          >
            <i className="icon-x me-2" />
            ຍົກເລີກ
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
