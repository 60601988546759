import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  USER_KEY,
  messageError,
  messageSuccess,
  onLoading,
  placeholderImage,
  url_path_images,
  url_upload_images,
} from "../../helper";
import axios from "axios";
import compressImage from "../../helper/uploadFile";
import Notiflix, { Notify } from "notiflix";
import { CREATE_SUB_IMAGE, DELETE_SUB_IMAGE, QUERY_SUB_IMAGE } from "./apollo";
import { useLazyQuery, useMutation } from "@apollo/client";
export default function PreviewSubImage({ communityID, index }) {
  const [subImage, setSubImage] = useState("");
  const [subImageList, setSubImageList] = useState([]);
  const [newLoad, setNewLoad] = useState(false);
  const [createSubImage] = useMutation(CREATE_SUB_IMAGE);
  const [deleteSubImage] = useMutation(DELETE_SUB_IMAGE);
  const [fetchSubImage] = useLazyQuery(QUERY_SUB_IMAGE, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setSubImageList(data?.subImages?.data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    fetchSubImage({
      variables: { where: { community: parseInt(communityID) } },
    });
  }, [newLoad, communityID]);

  const handleChangeSubImage = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        setSubImage(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res = await axios.post(url_upload_images, formData, {
          headers: {
            Authorization: localStorage.getItem(USER_KEY),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setSubImage(filename);
        } else {
          setSubImage("");
          Notify.messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  const handleSaveSubImage = async () => {
    if (!subImage) return;
    try {
      const res = await createSubImage({
        variables: {
          data: {
            community: parseInt(communityID),
            images: String(subImage),
          },
        },
      });
      if (res?.data?.createSubImage) {
        setNewLoad(!newLoad);
        messageSuccess("ອັບໂຫຼດສຳເລັດ");
        setSubImage("");
      }
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  const handleDeleteSubImage = async (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລຶບຮູບນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const res = await deleteSubImage({
            variables: { where: { id: parseInt(id) } },
          });
          if (res?.data?.deleteSubImage) {
            setNewLoad(!newLoad);
            messageSuccess("ລຶບສຳເລັດ");
          }
        } catch (error) {
          console.log(error);
          messageError("ລຶບບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <React.Fragment>
      {subImageList &&
        subImageList?.map((item, index) => {
          return (
            <div
              className="col-12 col-lg-4 col-md-4 col-sm-6 mt-2"
              style={{ position: "relative" }}
            >
              <img
                src={
                  item?.images
                    ? url_path_images + item?.images
                    : placeholderImage(100, 80)
                }
                style={{
                  height: 80,
                  width: "100%",
                  borderRadius: 7,
                }}
                alt="placeholder"
              />
              <span
                style={{
                  position: "absolute",
                  backgroundColor: "red",
                  borderBottomLeftRadius: 5,
                  marginLeft: -21,
                }}
                className="text-white px-1"
              >
                <a
                  href="javascript:void(0)"
                  onClick={() => handleDeleteSubImage(item?.id)}
                >
                  <i className="icon-x text-white" />
                </a>
              </span>
            </div>
          );
        })}
      <div
        className="col-12 col-lg-4 col-md-6 col-sm-6 mt-2"
        style={{ position: "relative" }}
      >
        <input
          type="file"
          id={`upload-sub-image-${index}`}
          onChange={handleChangeSubImage}
          accept="image/png, image/gif, image/jpeg"
          hidden
        />
        <label for={`upload-sub-image-${index}`}>
          <div
            style={{
              height: 80,
              width: 100,
              cursor: "pointer",
              backgroundImage: `url(${onLoading})`,
              backgroundPosition: "center",
              backgroundSize: "100%",
              textAlign: "center",
              borderRadius: 7,
            }}
          >
            {subImage ? (
              <img
                src={
                  subImage
                    ? url_path_images + subImage
                    : placeholderImage(100, 80)
                }
                alt={subImage}
                style={{
                  height: 80,
                  width: 100,
                  borderRadius: 7,
                }}
              />
            ) : (
              <div
                style={{
                  backgroundImage: `url(${placeholderImage(100, 80)})`,
                  height: 80,
                  width: 100,
                  alignItems: "center",
                  borderRadius: 7,
                  backgroundSize: "100%",
                }}
              ></div>
            )}
          </div>
        </label>
        {subImage && (
          <span
            style={{
              position: "absolute",
              backgroundColor: "#04c73e",
              borderBottomLeftRadius: 7,
              marginLeft: -27,
              marginTop: 1,
            }}
            className="px-1"
          >
            <a href="javascript:void(0)" onClick={() => handleSaveSubImage()}>
              <i className="fa fa-check text-white fs-4" />
            </a>
          </span>
        )}
      </div>
    </React.Fragment>
  );
}
