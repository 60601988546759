import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as _ from "lodash";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import {
  messageSuccess,
  messageError,
  valiDate,
  messageWarning,
  placeholderImage,
  url_path,
  addSlashes,
  customContentStateConverter,
  USER_KEY,
  onLoading,
  url_upload,
  url_upload_video,
  url_path_video,
} from "../../helper";
import { UPDATE_COMMUNITY } from "./apollo";
import { Form, Modal, Spinner } from "react-bootstrap";
import ListCatalogs from "../../helper/componentsList/SelectCatalogs";
import axios from "axios";
import { Notify } from "notiflix";
export default function EditVideo({ item, onSuccess }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [catalogData, setCatalogData] = useState({});
  const [videoFile, setVideoFile] = useState("");

  const [editorStateDescription, setEditorStateDescription] = useState(
    EditorState.createEmpty()
  );

  const description = draftToHtml(
    convertToRaw(editorStateDescription.getCurrentContent(item?.description))
  );

  const [updateSlide, { loading: adding }] = useMutation(UPDATE_COMMUNITY);

  useEffect(() => {
    if (item?.videos) {
      setVideoFile(item?.videos);
    }
    if (item?.catalog) {
      setCatalogData(item?.catalog);
    }
  }, [item]);

  useEffect(() => {
    if (item?.description) {
      const blocksFromHTML = convertFromHTML(item?.description);
      const editorStateDescription = EditorState.createWithContent(
        customContentStateConverter(
          ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          )
        )
      );
      setEditorStateDescription(editorStateDescription);
    }
  }, [item?.description]);

  const handleChangeFile = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      // const compressedFile = await compressVideo(file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        setVideoFile(e.target?.value);
        const formData = new FormData();
        formData.append("video", file);
        const res = await axios.post(url_upload_video, formData, {
          headers: {
            Authorization: localStorage.getItem(USER_KEY),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setVideoFile(filename);
        } else {
          setVideoFile("");
          Notify.messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };
  const { handleChange, errors, values, handleSubmit, resetForm, isDisabled } =
    useFormik({
      initialValues: {
        catalog: item?.catalog?.id,
        title: item?.title,
      },
      enableReinitialize: false,
      validate: (values) => {
        const errors = {};
        if (!item?.catalog?.id) {
          errors.catalogs = "ກະລຸນາເລືອກໝວດໝູ່";
        }
        if (!values?.title) {
          errors.title = "ກະລຸນາປ້ອນຫົວຂໍ້";
        }
        return errors;
      },
      onSubmit: async (values) => {
        try {
          const _arrayDescription = Array.from(description);
          const withEmojis = /\p{Emoji}/u;
          const _convertDescription = _arrayDescription?.map((char) => {
            if (withEmojis.test(char)) {
              return "&#x" + char.codePointAt(0).toString(16) + ";";
            } else {
              return char;
            }
          });
          const { data: updated } = await updateSlide({
            variables: {
              data: {
                title: values?.title,
                description: addSlashes(_convertDescription.join("")),
                videos: String(videoFile),
              },
              where: {
                id: parseInt(item?.id),
              },
            },
          });
          if (updated) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setShow(false);
            onSuccess(true);
            resetForm();
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການດຳເນີນງານຜິດພາດ");
        }
      },
    });

  return (
    <React.Fragment>
      <button
        className="btn btn-icon  btn-success rounded-circle me-2"
        onClick={() => setShow(true)}
      >
        <i className="icon-edit-3" />
      </button>
      <Modal
        centered
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">
            <i className="icon-edit-3" /> ແກ້ໄຂວີດີໂອ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-4 col-md-12 col-sm-12">
              <div className="form-group">
                <div style={{ textAlign: "center" }}>
                  <input
                    type="file"
                    id="file-upload"
                    onChange={handleChangeFile}
                    accept="video/*"
                    hidden
                  />
                  <label for="file-upload">
                    <div
                      style={{
                        height: 220,
                        width: 350,
                        cursor: "pointer",
                        display: "flex",
                        backgroundImage: `url(${onLoading})`,
                        justifyContent: "center",
                        backgroundPosition: "center",
                        backgroundSize: "100%",
                        textAlign: "center",
                        borderRadius: 7,
                      }}
                    >
                      {videoFile ? (
                        <video
                          src={
                            videoFile
                              ? url_path_video + videoFile
                              : placeholderImage(350, 220)
                          }
                          style={{
                            height: 220,
                            width: 350,
                            borderRadius: 7,
                          }}
                          autoPlay
                        />
                      ) : (
                        <div
                          style={{
                            backgroundImage: `url(${placeholderImage(
                              350,
                              220
                            )})`,
                            display: "flex",
                            height: 220,
                            width: 350,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 7,
                            backgroundSize: "100%",
                          }}
                        ></div>
                      )}
                    </div>
                  </label>
                </div>
              </div>
              <center>ອັບໂຫຼດຮູບພາບ</center>
            </div>
            <div className="col-12 col-lg-8 col-md-12 col-sm-12">
              <div className="form-group">
                <label>ຫົວຂໍ້{valiDate()}</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="title"
                  onChange={handleChange}
                  value={values.title}
                />
                <i className="text-danger">{errors.title}</i>
              </div>
              <div className="form-group">
                <label>ໝວດໝູ່{valiDate()}</label>
                <ListCatalogs
                  className={""}
                  style={""}
                  onChange={(res) => {
                    setCatalogData(res);
                  }}
                  value={catalogData?.id}
                />
                <i className="text-danger">{errors.catalogs}</i>
              </div>
              <div className="form-group">
                <label>ລາຍລະອຽດ</label>
                <div
                  className="w-100"
                  style={{
                    minHeight: 300,
                    border: "1px solid #ccc",
                    borderRadius: 5,
                  }}
                >
                  <Editor
                    editorState={editorStateDescription}
                    rows={10}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="px-3 custom-editor-content"
                    onEditorStateChange={setEditorStateDescription}
                    toolbar={
                      {
                        image: { uploadCallback: handleChangeFile },
                      }
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-lg hvr-shutter-out-horizontal"
            onClick={() => handleSubmit()}
            disabled={isDisabled}
          >
            <i className="icon-check-circle me-1" />
            ບັນທຶກ
          </button>
          <button
            type="button"
            className="btn btn-light btn-lg hvr-shutter-out-horizontal"
            onClick={() => handleClose()}
          >
            <i className="icon-x me-2" />
            ຍົກເລີກ
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
