import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { QUERY_EMPLOYEES_VOTES } from "./apollo";
import { ITEM_PER_PAGE, currency, setParams } from "../../helper";
import useReactRouter from "use-react-router";
import { FormSelect, Pagination } from "react-bootstrap";

export default function Employees_vote() {
  const [filteredData, setFilteredData] = useState();
  const { history, location } = useReactRouter();
  const queryParams = new URLSearchParams(location?.search);
  const [numberRows, setNumberRows] = useState(ITEM_PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [selectedService, setSelectedService] = useState(undefined);
  const [queryShowVotes, { data }] = useLazyQuery(QUERY_EMPLOYEES_VOTES, {
    fetchPolicy: "cache-and-network",
  });

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  console.log("selectedService",selectedService)

  useEffect(() => {
    queryShowVotes({
      variables: {
        where: {
          isService: selectedService ? selectedService : undefined,
        },
      },
    });
  }, [queryShowVotes, selectedService]);

  useEffect(() => {
    if (data?.summaryPointOfStaff?.data) {
      const filtered = data.summaryPointOfStaff.data.filter((value) => {
        const isServiceMatch =
          selectedService || value.isService === selectedService;
        const isSearchMatch = value.fullName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
        return isServiceMatch && isSearchMatch;
      });
      setFilteredData(filtered);
    }
  }, [searchValue , data]);

  const totalItem = data?.summaryPointOfStaff?.data.length;
  const totalPage = Math.ceil(totalItem / numberRows);

  const _onChangeRows = (e) => {
    let _value = e?.target?.value;
    history.push({
      search: setParams(`rows`, _value),
    });
    setNumberRows(parseInt(_value));
  };

  useEffect(() => {
    let page = queryParams.get("page");
    let rows = queryParams.get("rows");

    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(ITEM_PER_PAGE);
    }
  }, [queryParams]);

  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <div className="row">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <h4 style={{ fontWeight: "bold" }}>
              ລາຍງານການໂຫວດພະນັກງານ
              <span style={{ color: "red" }}>ດີເດັ່ນ</span>
            </h4>
          </div>

          <div className="d-flex align-items-center justify-content-end flex-wrap">
          {" "}
            ສະເເດງ {numberRows * currentPage - numberRows + 1} {"-"}{" "}
            {numberRows * currentPage} ລາຍການ ໃນຂໍ້ມູນທັງໜົດ{" "}
            {currency(totalItem ?? "")} ລາຍການ{" "}
            <div className="col-md-3">
              <FormSelect
                style={{ height: "40px" }}
                value={selectedService}
                onChange={(e) => {
                  setSelectedService(e.target.value || undefined);
                }}
              >
                <option value="">ທັງໝົດ</option>
                {[
                  { label: "ພະນັກງານສຳນັກງານໃຫຍ່", value: "COMPANY" },
                  { label: "ພະນັກງານສູນໂຊກໃຫຍ່", value: "CENTER_DC" },
                  { label: "ສູນຕ່າງແຂວງ", value: "CENTER_ALL" },
                  { label: "ສາຂາSAMEDAY", value: "SAMEDAY" },
                  { label: "ສາຂາNEXTDAY", value: "NEXTDAY" },
                ].map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </FormSelect>
            </div>
            <div className="col-md-2">
              <input
                type="text"
                placeholder="ຄົ້ນຫາ..."
                className="form-control"
                value={searchValue}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <div className="card-body mt-1">
          <div className="table-responsive card">
            <table className="table table-responsive mb-0 table-sm table-striped">
              <thead>
                <tr>
                  <th className="text-nowrap  text-center fs-5">#</th>
                  <th className="text-nowrap  text-left fs-5">
                    ຊື້ ແລະ ນາມສະກຸນ
                  </th>
                  <th className="text-nowrap  text-center fs-5">
                    ຄະແນນໂຫວດທັງໝົດ
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((value, index) => (
                  <tr key={index}>
                    <td className="text-nowrap text-center text-dark fs-5">
                      {index + 1}
                    </td>
                    <td className="text-nowrap text-left text-dark fs-5">
                      {value?.fullName} {value?.lastName}
                    </td>
                    <td className="text-nowrap text-center">
                      <b className="fs-3 text-danger">{value?.TotalPoint}</b>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="card-footer">
            {(() => {
              return (
                <Pagination
                  total={totalPage}
                  current={currentPage || 1}
                  onPageChange={(page) => {
                    history.push({
                      search: setParams("page", page.toString()),
                    });
                    setCurrentPage(parseInt(page));
                  }}
                />
              );
            })()}
          </div>
        </div>
      </div>
    </>
  );
}
