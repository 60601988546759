import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as _ from "lodash";

import {
  messageSuccess,
  messageError,
  valiDate,
  placeholderImage,
  onLoading,
  url_path_images,
  url_upload_images,
  USER_KEY,
} from "../../helper";
import { UPDATE_MEMBER } from "./apollo";
import { Form, Modal } from "react-bootstrap";
import compressImage from "../../helper/uploadFile";
import axios from "axios";
import { Notify } from "notiflix";
export default function EditMember({ item, onSuccess }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [updateMember, { loading: adding }] = useMutation(UPDATE_MEMBER);
  const [image, setImage] = useState("");

  useEffect(() => {
    if (item?.profileImage) {
      setImage(item?.profileImage);
    }
  }, [item]);
  const handleChangeFile = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        setImage(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res = await axios.post(url_upload_images, formData, {
          headers: {
            Authorization: localStorage.getItem(USER_KEY),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setImage(filename);
        } else {
          setImage("");
          Notify.messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  const { handleChange, errors, values, handleSubmit, resetForm, isDisabled } =
    useFormik({
      initialValues: {
        fullName: item?.fullName || "",
        phoneNumber: item?.phoneNumber || "",
        email: item?.email || "",
        description: item?.description || "",
        level: item?.level || "",
      },
      enableReinitialize: false,
      validate: (values) => {
        const errors = {};
        if (!values.fullName) {
          errors.fullName = "ກະລຸນາປ້ອນຊື່ ແລະ ນາມສະກຸນສະມາຊິກ";
        }
        if (!values.email) {
          errors.email = "ກະລຸນາປ້ອນອີເມວ";
        }
        if (String(values.phoneNumber).length !== 8) {
          errors.phoneNumber = "ເບີໂທລະສັບຕ້ອງມີ 8 ໂຕ";
        }
        if (!values.phoneNumber) errors.phoneNumber = "ກະລຸນາປ້ອນເບີໂທ";
        if (!values.level) errors.level = "ກະລຸນາເລືອກປະເພດສະມາຊິກ";
        return errors;
      },
      onSubmit: async (values) => {
        try {
          const { data: updated } = await updateMember({
            variables: {
              data: {
                fullName: String(values.fullName),
                phoneNumber: Number(values.phoneNumber),
                email: String(values.email),
                description: String(values.description),
                level: String(values.level),
                profileImage: String(image),
              },
              where:{
                id: parseInt(item?.id),
              }
            },
          });
          if (updated) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setShow(false);
            onSuccess(true);
            setImage("");
            resetForm();
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການດຳເນີນງານຜິດພາດ");
        }
      },
    });

  return (
    <React.Fragment>
      <button
        className="btn btn-icon  btn-success rounded-circle me-2"
        onClick={() => setShow(true)}
      >
        <i className="icon-edit-3" />
      </button>
      <Modal
        centered
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">
            <i className="icon-edit-3" /> ແກ້ໄຂຂໍ້ມູນສະມາຊິກ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="form-group">
                <div style={{ textAlign: "center" }}>
                  <input
                    type="file"
                    id="file-upload"
                    onChange={handleChangeFile}
                    accept="image/png, image/gif, image/jpeg"
                    hidden
                  />
                  <label for="file-upload">
                    <div
                      style={{
                        height: 150,
                        width: 150,
                        cursor: "pointer",
                        display: "flex",
                        backgroundImage: `url(${onLoading})`,
                        justifyContent: "center",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        textAlign: "center",
                        borderRadius: 150 / 2,
                      }}
                    >
                      {image ? (
                        <img
                          src={
                            image
                              ? url_path_images + image
                              : placeholderImage(150, 150)
                          }
                          alt={image}
                          style={{
                            height: 150,
                            width: 150,
                            borderRadius: 150 / 2,
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundImage: `url(${placeholderImage(
                              150,
                              150
                            )})`,
                            display: "flex",
                            height: 150,
                            width: 150,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 150 / 2,
                            backgroundSize: "cover",
                          }}
                        ></div>
                      )}
                    </div>
                  </label>
                </div>
              </div>
              <center>ອັບໂຫຼດຮູບພາບ</center>
            </div>
            <div className="col-12 col-lg-9">
              <div className="form-group">
                <label>ຊື່{valiDate()}</label>
                <input
                  name="fullName"
                  type="text"
                  placeholder="ປ້ອນຊື່ ແລະ ນາມສະກຸນສະມາຊິກ"
                  onChange={handleChange}
                  className={
                    errors.fullName ? "form-control is-invalid" : "form-control"
                  }
                  value={values.fullName}
                />
                <i className="text-danger">{errors.fullName}</i>
              </div>
              <div className="form-group">
                <label htmlFor="ciTy">ເບີໂທຕິດຕໍ່{valiDate()}</label>
                <input
                  name="phoneNumber"
                  type="number"
                  placeholder="ປ້ອນເບີໂທຕິດຕໍ່"
                  onChange={handleChange}
                  className="form-control"
                  value={values.phoneNumber}
                />
                <i className="text-danger">{errors?.phoneNumber}</i>
              </div>
              <div className="form-group">
                <label htmlFor="ciTy">ອີເມວ</label>
                <input
                  name="email"
                  type="email"
                  placeholder="ປ້ອນອີເມວ"
                  onChange={handleChange}
                  className="form-control"
                  value={values.email}
                />
              </div>
              <div className="form-group">
                <label>ປະເພດສະມາຊິກ{valiDate()}</label>
                <select
                  name="level"
                  className="form-control"
                  onChange={handleChange}
                  value={values.level}
                >
                  <option value="">ເລືອກປະເພດສະມາຊິກ</option>
                  <option value="GENERAL_MEMBER">GENERAL_MEMBER</option>
                  <option value="VIP_MEMBER">VIP_MEMBER</option>
                  <option value="ONLINE_SHOP">ONLINE_SHOP</option>
                </select>
                <i className="text-danger">{errors.level}</i>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="description">ໝາຍເຫດ</label>
                  <textarea
                    name="description"
                    rows={4}
                    className="form-control"
                    onChange={handleChange}
                    value={values.description}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-lg hvr-shutter-out-horizontal"
            onClick={() => handleSubmit()}
            disabled={isDisabled}
          >
            <i className="icon-check-circle me-1" />
            ບັນທຶກ
          </button>
          <button
            type="button"
            className="btn btn-light btn-lg hvr-shutter-out-horizontal"
            onClick={() => handleClose()}
          >
            <i className="icon-x me-2" />
            ຍົກເລີກ
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
