import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as _ from "lodash";

import {
  messageSuccess,
  messageError,
  valiDate,
  placeholderImage,
  USER_KEY,
  url_upload_images,
  onLoading,
  url_path_images,
} from "../../helper";
import { UPDATE_USER } from "./apollo";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";
import compressImage from "../../helper/uploadFile";
import axios from "axios";
import { Notify } from "notiflix";
export default function EditMember({ item, onSuccess }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [image, setImage] = useState("");

  const [updateUser, { loading: adding }] = useMutation(UPDATE_USER);

  useEffect(() => {
    if (item?.profileImagePath) {
      setImage(item?.profileImagePath);
    }

  }, [item]);

  const handleChangeFile = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        setImage(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res = await axios.post(url_upload_images, formData, {
          headers: {
            Authorization: localStorage.getItem(USER_KEY),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setImage(filename);
        } else {
          setImage("");
          Notify.messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  const { handleChange, errors, values, handleSubmit, resetForm, isDisabled } =
    useFormik({
      initialValues: {
        firstName: item?.firstName || "",
        lastName: item?.lastName || "",
        username: item?.username || "",
        phoneNumber: item?.phoneNumber || "",
        email: item?.email || "",
        role: item?.role || "",
        idCard: item?.idCard || "",
      },
      enableReinitialize: true,
      validate: (values) => {
        const errors = {};
        if (!values.firstName) {
          errors.firstName = "ກະລຸນາປ້ອນຊື່ສະມາຊິກ";
        }
        if (!values.lastName) {
          errors.lastName = "ກະລຸນາປ້ອນນາມສະມາຊິກ";
        }
        if (String(values.phoneNumber).length !== 8) {
          errors.phoneNumber = "ເບີໂທລະສັບຕ້ອງມີ 8 ໂຕ";
        }
        if (!values.phoneNumber) errors.phoneNumber = "ກະລຸນາປ້ອນເບີໂທ";
        if (!values.role) errors.role = "ກະລຸນາເລືອກສິດການນຳໃຊ້";
        return errors;
      },
      onSubmit: async (values) => {
        try {
          const { data: updated } = await updateUser({
            variables: {
              data: {
                firstName: String(values?.firstName),
                lastName: String(values?.lastName),
                username: String(values?.username),
                email: String(values?.email),
                profileImagePath: String(image),
                phoneNumber: parseInt(values?.phoneNumber),
                role: String(values.role),
                idCard: String(values.idCard),
              },
              where: {
                id: parseInt(item?.id),
              },
            },
          });
          if (updated) {
            messageSuccess("ການດຳເນີນງານສຳເລັດ");
            setShow(false);
            onSuccess(true);
            setImage("");
            resetForm();
          } else {
            messageError("ການດຳເນີນງານບໍ່ສຳເລັດ");
          }
        } catch (error) {
          messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
        }
      },
    });

  return (
    <React.Fragment>
      <button
        className="btn btn-icon  btn-success rounded-circle me-2"
        onClick={() => setShow(true)}
      >
        <i className="icon-edit-3" />
      </button>
      <Modal
        centered
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">
            <i className="icon-edit-3" /> ແກ້ໄຂຂໍ້ມູນຜູ້ໃຊ້
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-3">
            <div className="form-group">
                <div style={{ textAlign: "center" }}>
                  <input
                    type="file"
                    id="file-upload"
                    onChange={handleChangeFile}
                    accept="image/png, image/gif, image/jpeg"
                    hidden
                  />
                  <label for="file-upload">
                    <div
                      style={{
                        height: 150,
                        width:150,
                        cursor: "pointer",
                        display: "flex",
                        backgroundImage: `url(${onLoading})`,
                        justifyContent: "center",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        textAlign: "center",
                        borderRadius: 150/2,
                      }}
                    >
                      {image ? (
                        <img
                          src={image ? url_path_images + image : placeholderImage(150,150)}
                          alt={image}
                          style={{
                            height: 150,
                            width:150,
                            borderRadius:150/2,
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundImage: `url(${placeholderImage(150,150)})`,
                            display: "flex",
                            height: 150,
                            width:150,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 150/2,
                            backgroundSize: "cover",
                          }}
                        ></div>
                      )}
                    </div>
                  </label>
                </div>
              </div>
              <center>ອັບໂຫຼດຮູບພາບ</center>
            </div>
            <div className="col-12 col-lg-9">
              <div className="form-group">
                <label>ຊື່{valiDate()}</label>
                <input
                  name="firstName"
                  type="text"
                  placeholder="ປ້ອນຊື່ສະມາຊິກ"
                  onChange={handleChange}
                  className={
                    errors.firstName
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  value={values.firstName}
                />
                <i className="text-danger">{errors.firstName}</i>
              </div>
              <div className="form-group">
                <label>ນາມສະກຸນ{valiDate()}</label>
                <input
                  name="lastName"
                  type="text"
                  placeholder="ປ້ອນນາມສະກຸນ"
                  onChange={handleChange}
                  className={
                    errors.lastName ? "form-control is-invalid" : "form-control"
                  }
                  value={values.lastName}
                />
                <i className="text-danger">{errors.lastName}</i>
              </div>
              <div className="form-group">
                <label>ຊື່ຫຼີ້ນ</label>
                <input
                  name="username"
                  type="text"
                  placeholder="ປ້ອນຊື່ຫຼີ້ນ"
                  onChange={handleChange}
                  className="form-control"
                  value={values.username}
                />
              </div>
              <div className="form-group">
                <label htmlFor="ciTy">ເບີໂທຕິດຕໍ່{valiDate()}</label>
                <input
                  name="phoneNumber"
                  type="number"
                  placeholder="ປ້ອນເບີໂທຕິດຕໍ່"
                  onChange={handleChange}
                  className="form-control"
                  value={values.phoneNumber}
                />
                <i className="text-danger">{errors?.phoneNumber}</i>
              </div>
              <div className="form-group">
                <label htmlFor="ciTy">ອີເມວ</label>
                <input
                  name="email"
                  type="email"
                  placeholder="ປ້ອນອີເມວ"
                  onChange={handleChange}
                  className="form-control"
                  value={values.email}
                />
              </div>
              <div className="form-group">
                <label>ສິດເຂົ້າໃຊ້ລະບົບ{valiDate()}</label>
                <select
                  name="role"
                  className="form-control"
                  onChange={handleChange}
                  value={values.role}
                >
                  <option value="">ເລືອກສິດເຂົ້າໃຊ້ລະບົບ</option>
                  <option value="SUPER_ADMIN">SUPER_ADMIN</option>
                  <option value="ADMIN">ADMIN</option>
                  <option value="USERS">USERS</option>
                </select>
                <i className="text-danger">{errors.role}</i>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="ciTy">ເລກບັດປະຈຳຕົວ/ພັສປອດ</label>
                  <input
                    name="idCard"
                    type="number"
                    className="form-control"
                    value={values.idCard}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-lg hvr-shutter-out-horizontal"
            onClick={() => handleSubmit()}
            disabled={isDisabled}
          >
            <i className="icon-check-circle me-1" />
            ບັນທຶກ
          </button>
          <button
            type="button"
            className="btn btn-light btn-lg hvr-shutter-out-horizontal"
            onClick={() => handleClose()}
          >
            <i className="icon-x me-2" />
            ຍົກເລີກ
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
