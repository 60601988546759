import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import Pagination from "react-responsive-pagination";
import {
  formatDateDash,
  ITEM_PER_PAGE,
  setParams,
  messageSuccess,
  messageError,
  StatusRegister,
  getStaffLogin,
  StatusPosition,
} from "../../helper";

import "./app.scss";
import { QUERY_DATA, UPDATE_DATA } from "./apollo";
import useReactRouter from "use-react-router";
import Notiflix from "notiflix";
import PreviewImage from "../../helper/previewImage";
import PreviewFiles from "../../helper/previewFiles";
import LimitData from "../../controllers/LimitData";

export default function Register() {
  const { history, location } = useReactRouter();
  const queryParams = new URLSearchParams(location?.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberRows, setNumberRows] = useState(ITEM_PER_PAGE);
  const [newLoadData, setNewLoadData] = useState(false);
  const getUser = getStaffLogin()?.id;
  const [listData, setListDaa] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [updateData] = useMutation(UPDATE_DATA);
  const [fetchCatalog, { data: catalogData }] = useLazyQuery(QUERY_DATA, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    fetchCatalog({
      variables: {
        where: {
          firstName: searchValue ? String(searchValue) : undefined,
        },
        skip: numberRows * currentPage - numberRows,
        limit: numberRows,
      },
    });
  }, [newLoadData, currentPage, numberRows, fetchCatalog, searchValue]);

  useEffect(() => {
    setListDaa(catalogData?.registerJobs?.data);
  }, [catalogData]);

  const totalItem = catalogData?.registerJobs?.total;
  const totalPage = Math.ceil(totalItem / numberRows);

  const NO = (index) => {
    const no = numberRows * currentPage - numberRows;
    if (numberRows > 0) {
      return no + index + 1;
    } else {
      return index + 1;
    }
  };

  const _onChangeRows = (e) => {
    let _value = e?.target?.value;
    history.push({
      search: setParams(`rows`, _value),
    });
    setNumberRows(parseInt(_value));
  };

  useEffect(() => {
    let page = queryParams.get("page");
    let rows = queryParams.get("rows");

    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(ITEM_PER_PAGE);
    }
  }, [queryParams]);
  const _handleUpdate = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      `ທ່ານຕ້ອງການຢືນຢັນແທ້ ຫຼື ບໍ່?`,
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _updated = await updateData({
            variables: {
              data: {
                status: "APPROVED",
                updatedBy: parseInt(getUser),
              },
              where: {
                id: parseInt(id),
              },
            },
          });
          if (_updated) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setNewLoadData(!newLoadData);
          }
        } catch (error) {
          console.log(error);
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <div className="row">
          <div className="col-md-12" style={{ zIndex: 1 }}>
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <p className="mb-md-0 mb-2 d-flex align-items-center gap-2">ລາຍງານຟອມສະໝັກງານ</p>
                  <div className="d-flex align-items-center flex-wrap">
                    <LimitData
                      numberRows={numberRows}
                      onChangeRows={_onChangeRows}
                      onSearch={(_onSearch) => {
                        setSearchValue(_onSearch);
                      }}
                      numberPage={currentPage}
                      total={totalItem}
                      hiddenSearch={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div id="cart" className="iq-product-tracker-card show b-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table className="table table-responsive mb-0 table-sm">
                        <thead>
                          <tr>
                            <th width={10}>ລຳດັບ</th>
                            <th className="text-nowrap">ໂປຣໄຟລ໌</th>
                            <th className="text-nowrap">ຊື້ ແລະ ນາມສະກຸມ</th>
                            <th className="text-nowrap"> ເບີໂທ</th>
                            <th className="text-nowrap text-center">ວັນເດືອນປີເກີດ</th>
                            <th className="text-nowrap text-start">ຄຳອະທິບາຍ</th>
                            <th className="text-nowrap text-start">ໄຟລ໌ເອກະສານ</th>
                            <th className="text-nowrap text-start">ວັນທີສະໝັກ</th>
                            <th className="text-nowrap text-start">ສະຖານະ</th>
                            <th className="text-nowrap text-start">ຕຳແໜ່ງສະໝັກ</th>
                            <th className="text-nowrap text-start">ຈັດກາານ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listData?.map((item, index) => (
                            <>
                              <tr
                                data-item="list"
                                key={index}
                                style={{
                                  backgroundColor:
                                    item?.status === "APPROVED" ? "#a2dea6" : item?.status === "REJECT" ? "#dec1d1" : "",
                                }}
                              >
                                <td className="text-center">{NO(index)}</td>
                                <td scope="row" className="text-nowrap " width={100}>
                                  <PreviewImage image={item?.profileImage} width={100} height={100} />
                                </td>
                                <td className="align-top">
                                  {item?.firstName ? item?.firstName : " "} {item?.lastName ? item?.lastName : " "}
                                </td>
                                <td className="align-top">{item?.phoneNumber ? item?.phoneNumber : " "}</td>
                                <td className="align-top">{item?.dateOfBirth ? formatDateDash(item?.dateOfBirth) : " "}</td>
                                <td className="align-top">{item?.description ? item?.description : " "}</td>
                                <td className="align-top">
                                  <PreviewFiles image={item?.files} />
                                </td>
                                <td className="align-top">{item?.createdAt ? formatDateDash(item?.createdAt) : " "}</td>
                                <td className="align-top">
                                  <>{item?.status ? StatusRegister(item?.status) : " "}</>
                                </td>
                                <td className="align-top">
                                  <>{item?.position ? StatusPosition(item?.position) : " "}</>
                                </td>
                                <td className="align-top">
                                  {item?.status === "REQUESTING" ? (
                                    <button onClick={() => _handleUpdate(item?.id)} className="btn btn-sm btn-success ">
                                      <i className="fa-regular fa-circle-check me-1" />
                                      ຮັບຟອມ
                                    </button>
                                  ) : (
                                    <>
                                      {item?.updatedBy?.firstName ? item?.updatedBy?.firstName : " "}{" "}
                                      {item?.updatedBy?.lastName ? item?.updatedBy?.lastName : " "}
                                      <hr />
                                      {item?.updatedAt ? formatDateDash(item?.updatedAt) : " "}
                                    </>
                                  )}
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer">
                      {(() => {
                        return (
                          <Pagination
                            total={totalPage}
                            current={currentPage || 1}
                            onPageChange={(page) => {
                              history.push({
                                search: setParams("page", page.toString()),
                              });
                              setNumberRows(parseInt(page));
                            }}
                          />
                        );
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
