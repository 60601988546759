import { gql } from "apollo-boost";

export const QUERY_COMMUNITY = gql`
  query Communities($where: CommunityWhereInput, $limit: Int, $skip: Int) {
    communities(where: $where, limit: $limit, skip: $skip) {
      data {
        id
        title
        description
        image
        subImages
        isPublic
        createdAt
        updatedAt
        createdBy {
          id
          firstName
          email
          lastName
          phoneNumber
          profileImagePath
        }
        catalog {
          id
          title
          type
        }
      }
      total
    }
  }
`;
export const CREATE_COMMUNITY = gql`
  mutation CreateCommunity($data: CommunityInput!) {
    createCommunity(data: $data) {
      data
      status
    }
  }
`;

export const UPDATE_COMMUNITY = gql`
  mutation UpdateCommunity($data: CommunityInput!, $where: WhereById!) {
    updateCommunity(data: $data, where: $where) {
      data
      status
    }
  }
`;

export const DELETE_COMMUNITY = gql`
  mutation DeleteCommunity($where: WhereById!) {
    deleteCommunity(where: $where) {
      data
      status
    }
  }
`;


export const QUERY_SUB_IMAGE = gql`
 query SubImages($where: SubImageWhereInput, $limit: Int, $skip: Int) {
  subImages(where: $where, limit: $limit, skip: $skip) {
    data {
      createdAt
      id
      images
    }
  }
}
`;

export const CREATE_SUB_IMAGE = gql`
mutation CreateSubImage($data: SubImageInput!) {
  createSubImage(data: $data) {
    data
    status
  }
}
`;

export const UPDATE_SUB_IMAGE = gql`
mutation UpdateSubImage($data: SubImageInput!, $where: WhereById!) {
  updateSubImage(data: $data, where: $where) {
    data
    status
  }
}
`;

export const DELETE_SUB_IMAGE = gql`
mutation DeleteSubImage($where: WhereById!) {
  deleteSubImage(where: $where) {
    data
    status
  }
}
`;
