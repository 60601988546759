import React, { useEffect, useState } from "react";
import Notiflix from "notiflix";
import {
  url_path_images,
  getStaffLogin,
  loadingScreen,
  socketServer,
} from "../helper";
export default function NavBar({ history }) {
  const [branch, setLocalBranch] = useState();
  const [userData, setUserData] = useState({});
  useEffect(() => {
    const _local = getStaffLogin();
    setUserData(_local?.data || {});
    const localSideBarMini = localStorage.getItem("SIDEBAR_MINI");
    if (localSideBarMini === "true") {
      document.body.classList.add("mini-sidebar");
    }
  }, []);

  const _onLogout = async () => {
    Notiflix.Confirm.show(
      "ຢຶນຢັນ",
      "ທ່ານຕ້ອງການອອກຈາກລະບົບແທ້ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async function () {
        localStorage.clear();
        sessionStorage.clear();
        loadingScreen();
        setTimeout(() => {
          Notiflix.Loading.remove();
          window.location.replace("/login");
        }, 1500);
      },
      function () {}
    );
  };
  return (
    <nav
      className="nav navbar navbar-expand-xl iq-navbar sticky-top"
      style={{ position: "fixed", right: 0, left: 0 }}
    >
      <div className="container-fluid navbar-inner text-center">
        <a onClick={() => history.push("/home")} className="navbar-brand">
          {/*Logo start*/}
          <div className="logo-main text-center">
            <div className="logo-normal">
              <img
                src={url_path_images + branch?.coverImage}
                className="img-fluid"
                alt=""
                style={{
                  maxHeight: 50,
                  BorderRadius: "30% 70% 54% 46% / 30% 53% 47% 70%",
                }}
              />
              <span className="fs-4 ms-2">{branch?.title_la}</span>
            </div>
            <div className="logo-mini">
              <img
                src={url_path_images + branch?.coverImage}
                className="img-fluid"
                alt=""
                style={{
                  maxHeight: 50,
                  BorderRadius: "30% 70% 54% 46% / 30% 53% 47% 70%",
                }}
              />
              <span className="fs-4 ms-2">{branch?.title_la}</span>
            </div>
          </div>
        </a>
        <div
          className="sidebar-toggle"
          data-toggle="sidebar"
          data-active="true"
        >
          <i className="icon d-flex">
            <svg className="icon-20" width={20} viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
              />
            </svg>
          </i>
        </div>

        <div className="navbar" id="navbarSupportedContent">
          <ul className="mb-2 navbar-nav ms-auto align-items-center mb-lg-0">
            <li className="nav-item">
              <a
                className="py-0 nav-link d-flex align-items-center"
                href="#"
                role="button"
                onClick={_onLogout}
              >
                <div className="btn btn-primary btn-icon btn-sm rounded-pill hvr-pulse">
                  <span className="btn-inner">
                  <i className="icon-log-out" />
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
