import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { QUERY_BRANCH } from "./apollo";
import useReactRouter from "use-react-router";
import LimitData from "../../controllers/LimitData";
import SelectBranch from "../../controllers/SelectBranch";
import { ITEM_PER_PAGE, formatDateTime, setParams } from "../../helper";
import Pagination from "react-responsive-pagination";

export default function FeedBackBranch() {
  const { history, location } = useReactRouter();
  const queryParams = new URLSearchParams(location?.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberRows, setNumberRows] = useState(ITEM_PER_PAGE);
  const [branch, setBranch] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [listBranch, setListBranch] = useState();
  const [query_report_branch, { data }] = useLazyQuery(QUERY_BRANCH, {
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    query_report_branch({
      variables: {
        where: {
          branch: listBranch?.id_branch
            ? parseInt(listBranch?.id_branch)
            : undefined,
        },
        skip: numberRows * currentPage - numberRows,
        limit: numberRows,
      },
    });
  }, [query_report_branch, currentPage, numberRows, searchValue, listBranch]);

  useEffect(() => {
    setBranch(data?.commentBranchSchemas?.data);
  }, [data]);

  const totalItem = data?.commentBranchSchemas?.total;
  const totalPage = Math.ceil(totalItem / numberRows);

  const NO = (index) => {
    const no = numberRows * currentPage - numberRows;
    if (numberRows > 0) {
      return no + index + 1;
    } else {
      return index + 1;
    }
  };

  const _onChangeRows = (e) => {
    let _value = e?.target?.value;
    history.push({
      search: setParams(`rows`, _value),
    });
    setNumberRows(parseInt(_value));
  };

  useEffect(() => {
    let page = queryParams.get("page");
    let rows = queryParams.get("rows");

    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(ITEM_PER_PAGE);
    }
  }, [queryParams]);
  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <div className="row">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <p className="mb-md-0 mb-2 d-flex align-items-center gap-2">
              <SelectBranch
                value={listBranch?.id_branch}
                onChange={(obj) => {
                  setListBranch(obj);
                }}
              />
            </p>
            <div className="d-flex align-items-center flex-wrap">
              <LimitData
                numberRows={numberRows}
                onChangeRows={_onChangeRows}
                onSearch={(_onSearch) => {
                  setSearchValue(_onSearch);
                }}
                numberPage={currentPage}
                total={totalItem}
                hiddenSearch={true}
              />
            </div>
          </div>
        </div>
        <div className="text-dark">
        ຄຳອະທິບາຍຄະແນນ{" "}
          <b className="me-3">1: ບໍລິການບໍ່ດີ,</b>
          <b className="me-3">2:ຕ້ອງປັບປຸງ,</b>
          <b className="me-3">3:ບໍລິການພໍໃຊ້,</b>
          <b className="me-3">4:ບໍລິການດີ</b>
          <b className="me-3">5: ບໍລິການດີຫຼາຍ</b>
        </div>
        <div className="card mt-1">
          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="table table-responsive mb-0 table-sm rounded">
                <thead>
                  <tr>
                    <th className="text-nowrap  text-center">#</th>
                    <th className="text-nowrap  text-start">ສາຂາ</th>
                    <th className="text-nowrap  text-center">ເບີໂທ</th>
                    <th className="text-nowrap  text-center">ຄະແນນ</th>
                    <th className="text-nowrap  text-start ">ຄວາມຄິດເຫັນ</th>
                    <th className="text-nowrap  text-center ">ວັນທີ່</th>
                  </tr>
                </thead>
                <tbody>
                  {branch?.map((values, index) => (
                    <tr key={index} style={{ color: "black" }}>
                      <td className="text-nowrap text-center">{NO(index)}</td>
                      <td className="text-nowrap text-start">
                        {values?.branch?.branch_name}
                      </td>
                      <td className="text-nowrap text-center">
                        {values?.phoneNumber}
                      </td>
                      <td className="text-nowrap text-center">
                        <b className="fs-5"> {values?.point}</b>
                      </td>
                      <td className="text-nowrap text-start">
                        {values?.description}
                      </td>
                      <td className="text-nowrap text-center">
                        {formatDateTime(values?.createdAt)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card-footer">
              {(() => {
                return (
                  <Pagination
                    total={totalPage}
                    current={currentPage || 1}
                    onPageChange={(page) => {
                      history.push({
                        search: setParams("page", page.toString()),
                      });
                      setNumberRows(parseInt(page));
                    }}
                  />
                );
              })()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
