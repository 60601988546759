import { gql } from "apollo-boost";

export const QUERY_DATA = gql`
  query RegisterJobs($skip: Int, $limit: Int, $where: RegisterJobWhereInput) {
  registerJobs(skip: $skip, limit: $limit, where: $where) {
    total
    data {
      createdAt
      dateOfBirth
      description
      files
      firstName
      id
      lastName
      phoneNumber
      profileImage
      status
      updatedAt
      updatedBy {
        lastName
        firstName
      }
      position
    }
  }
}
`;

export const UPDATE_DATA = gql`
  mutation UpdateRegisterJob($data: RegisterJobInput!, $where: WhereById!) {
    updateRegisterJob(data: $data, where: $where) {
      status
    }
  }
`;
