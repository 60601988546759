import React from "react";
import moment from "moment";
import Notiflix, { Loading } from "notiflix";
import loading from "../img/loading.gif";
import { io } from "socket.io-client";
import sound from "../audio/sound.mp3";
import loadingImage from "../img/loading.gif";
import _placeholderImage from "../img/placeholder.png";
import { useLocation } from "react-router-dom";
var start_year = new Date().getFullYear();
export const ITEM_PER_PAGE = 50;
export const url_path_images =
  "https://storage.googleapis.com/ansx/website/images/";
export const url_path_video =
  "https://storage.googleapis.com/ansx/website/videos/";
export const url_path_files =
  "https://storage.googleapis.com/ansx/website/files/";
export const url_upload_images = "https://dash.api.anousith-express.com/images";
 //export const url_upload_images = "http://localhost:4000/images";
export const url_upload_video = "https://dash.api.anousith-express.com/videos";
export const url_upload_files = "https://dash.api.anousith-express.com/files";
// export const url_upload_images = "http://localhost:4500/upload";
export const onLoading = loadingImage;
export const replaceImage = _placeholderImage;

export const CHECK_BOX_STYLE = {
  width: "20px",
  height: "20px",
};
export const placeholderImage = (width, height) => {
  return `https://via.placeholder.com/${width}x${height}`;
};

export const addSlashes = (str) => {
  return (str + "").replace(/[\\"']/g, "\\$&").replace(/\u0000/g, "\\0");
};

export const newSound = new Audio(sound);
export const socketServer = io("http://localhost:4000");
// best color
export const PRIMARY_COLOR = "#d6204b";
export const SECONDARY_COLOR = "#1fbb62";
export const TERTIARY_COLOR = "#1b97c8";

export const USER_KEY = "ANS-WEB-ADMIN-USER";
//get staff login
export const getStaffLogin = () => {
  let token = localStorage.getItem(USER_KEY);
  if (token && token !== "null" && token !== "undefined") {
    return parseJwt(token);
  }
  return null;
};

export const userRole = () => {
  const json = getStaffLogin();
  if (json && Object.keys(json).length) {
    return json?.role;
  }
  return "UNKNOWN";
};

// get user role from token
const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

//clear localStorage
export const clearLocalStorage = () => {
  localStorage.removeItem(USER_KEY);
};

// isMain and isPublic
export const createdAt_gte = (date) => {
  const _date = date ? date : new Date();
  return moment(_date).format("YYYY-MM-DD");
};

export const createdAt_lte = (date) => {
  const _date = date ? date : new Date();
  return moment(_date).add(1, "days").format("YYYY-MM-DD");
};

export const currentDate = (date) => {
  const _date = date ? date : new Date();
  return moment(_date).format("DD-MM-YYYY");
};

export const unixTimeFormat = (x) => {
  let res = moment.unix(x).format("YYYY-MM-DD HH:SS");
  if (res) return res;
};
// year
export const getYeear = () => {
  let getYeear = [];
  for (let date = 1990; date < start_year + 1; date++) {
    getYeear.push(date);
  }
  return getYeear;
};
//  category_financetype
export const financetype = (item) => {
  if (item === "INCOME") {
    return "ລາຍຮັບ";
  } else {
    return "ລາຍຈ່າຍ";
  }
};

//  unitCurrency
export const unitCurrency = (item) => {
  if (item === "COD") {
    return "ເງິນສົດ";
  } else if (item === "TRANSFER") {
    return "ເງິນໂອນ";
  } else {
    return "-";
  }
};

// status category_financetype
export const statusFinancetype = (item) => {
  if (item === false) {
    return "ປິດໃຊ້ງານ";
  } else {
    return "ເປີດໃຊ້ງານ";
  }
};

// educationlevel
export const getEducationLevel = () => {
  let getYear = [];
  var start_year = new Date().getFullYear();
  for (var i = start_year; i < start_year + 10; i++) {
    getYear.push(i - 1 + "-" + i);
  }
  return getYear;
};

// ກຳນົດ ເພດ
export const setGender = (SetGender) => {
  let res = "";
  switch (SetGender) {
    case "MALE":
      res = "ຊາຍ";
      break;
    case "FEMALE":
      res = "ຍິງ";
      break;
    case "OTHER":
      res = "ອື່ນໆ";
      break;
  }
  return res;
};

// ກຳນົດ ສະຖານະປິດເປີດ
export const setSwich = (string) => {
  let res = "";
  switch (string) {
    case true:
      res = "ເປີດ";
      break;
    case false:
      res = "ປິດ";
      break;
  }
  return res;
};

export const ITEM_PAGE_LIST = [
  { itemPage: "1" },
  { itemPage: "2" },
  { itemPage: "3" },
  { itemPage: "4" },
  { itemPage: "5" },
  { itemPage: "ທັງໜົດ" },
];

export const bracket = (tag) => {
  let st = "(";
  let en = ")";
  let i = st + tag + en;
  return i;
};

// ວັນທີເດືອນປີເລີ່ມວັນທີ ເລີ່ມເດືອນ ເລີ່ມປີ
export const startOfMonth = () => {
  return moment().clone().startOf("month").format("YYYY-MM-DD");
};

// ວັນທີເດືອນປີ ທ້າຍວັນທີ ທ້າວເດືອນ ທ້າຍປີ
export const endOfMonth = () => {
  return moment().clone().endOf("month").format("YYYY-MM-DD");
};

//  ກຳນົດ ອາຍຸ
export const age = (age) => {
  let today = new Date();
  let y = today.getFullYear();
  let dob = moment(age).format("YYYY");
  return y - dob;
};

// ກຳນົດ ຟໍແມັດເງິນ
export const currency = (value) => {
  let currencys = new Intl.NumberFormat("en-CA").format(value);
  if (value !== 0) return currencys;
  else if (value === 0) return "0";
  else return "";
};
// ກຳນົດ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const formatTime = (dateTime) => {
  let resp = moment(dateTime).format("HH:mm");
  return resp;
};

// ກຳນົດ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const formatDateTime = (dateTime) => {
  // moment.locale("lo");
  let resp = moment(dateTime).format("DD-MM-YYYY, HH:mm");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ພາສາລາວ)
export const formatDate = (dateTime) => {
  // moment.locale("lo");
  let resp = moment(dateTime).format("DD MMMM YYYY");
  if (dateTime) return resp;
  else return "-";
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(/)
export const formateDateSlash = (slashData) => {
  let resp = moment(slashData).format("YYYY/MM/DD");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const formatDateDash = (dashDate) => {
  let resp = moment(dashDate).format("YYYY-MM-DD");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ຖັດໄປ 1 ອາທິດ)
export const nextSevenDay = () => {
  var nextSenvenDay = moment().add(7, "days");
  var nextSevenDays = moment(nextSenvenDay).format("YYYY-MM-DD");
  return nextSevenDays;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ຖັດໄປ 1 ມື້)
export const nextOneDay = () => {
  var nextOneDay = moment().add(1, "days");
  var nextOneDays = moment(nextOneDay).format("YYYY-MM-DD");
  return nextOneDays;
};
// ກຳນົດ ວັນທີປັດຈຸບັນ(ຖັດໄປ 1 ມື້)
export const yesTerDay = () => {
  var yesTerday = moment().subtract(1, "days");
  var yesTerdays = moment(yesTerday).format("YYYY-MM-DD");
  return yesTerdays;
};

// ກຳນົດ ວັນທີ ແລະ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const toDay = () => {
  moment.locale("lo");
  var today = new Date();
  var todays = moment(today).format("YYYY/MM/DD, HH:mm");
  return todays;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const toDayDash = () => {
  var today = new Date();
  var todays = moment(today).format("YYYY-MM-DD");
  return todays;
};
export const reconvertDay = (day) => {
  let result = "";
  switch (day) {
    case "MONDAY":
      result = "ຈັນ";
      break;
    case "TUESDAY":
      result = "ອັງຄານ";
      break;
    case "WEDNESDAY":
      result = "ພຸດ";
      break;
    case "THURSDAY":
      result = "ພະຫັດ";
      break;
    case "FRIDAY":
      result = "ສຸກ";
      break;
    case "SATURDAY":
      result = "ເສົາ";
      break;
    case "SUNDAY":
      result = "ອາທິດ";
      break;
    default:
      result = "ຈັນ";
  }
  return result;
};

// ກຳນົດ ເຄື່ອງໝາຍ ບັງຄັບໃຫ້ປ້ອນຂໍ້ມູນ
export const valiDate = () => {
  return <font style={{ color: "red" }}> * </font>;
};

// ກຳນົດ ການຈັດລຽງຕາມຄ່າຕ່າງໆ
export const SortBy = ({ sort }) => {
  return (
    <div
      onClick={() => {
        sort();
      }}
      style={{
        float: "right",
        marginRight: 5,
        cursor: "pointer",
      }}
    >
      <i className="fa fa-sort"></i>
    </div>
  );
};

export const paymentMethodFinance = (item) => {
  if (item === "PAY_ONLINE") {
    return "ອອນລາຍ";
  } else if (item === "PAY_CASH") {
    return "ເງີນສົດ";
  } else {
    return "-";
  }
};

export const startLoading = () => {
  return Notiflix.Loading.standard("Loading...");
};
export const stopLoading = () => {
  return Notiflix.Loading.remove();
};

export const messageSuccess = (e) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.success(e);
};

export const messageError = (e) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.failure(e);
};
export const messageWarning = (e) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.warning(e);
};

export const messageConfirm = (title, action) => {
  Notiflix.Confirm.show(
    "ເເຈ້ງເຕືອນ",
    title,
    "ຕົກລົງ",
    "ຍົກເລີກ",
    async function () {
      action();
    },
    function () {
      return false;
    }
  );
};

export const _paymentStatus = [
  { name: "SOMETIME" },
  { name: "WEEKLY" },
  { name: "MONTH" },
  { name: "YEARLY" },
];

export const _payCurrency = [
  { name: "LAK" },
  { name: "BAHT" },
  { name: "DOLLAR" },
];

export const formatStartDate = (date) => {
  let _date = moment(date).format("YYYY-MM-DD");
  return _date;
};
export const formatEndDate = (date) => {
  let _endDate = moment(date).add(1, "days").format("YYYY-MM-DD");
  return _endDate;
};

export const loadingData = (size, content) => {
  return (
    <>
      <img
        src={loading}
        alt="loading"
        style={{ width: size ? size : 25, height: size ? size : 25 }}
      />{" "}
      <span className="text-primary">{content}</span>
    </>
  );
};

export const loadingScreen = () => {
  return Loading.hourglass({
    backgroundColor: "rgba(245, 74, 7,0.2)",
    svgColor: "#f54b07",
  });
};
export const closeLoadingScreen = () => {
  return Loading.move();
};

export const loadingDatalist = (size = 25, content) => {
  return (
    <span>
      <img src={loading} alt="loading" style={{ width: size }} />{" "}
      <span className="text-primary">{content || "ກຳລັງໂຫຼດຂໍ້ມູນ..."}</span>
    </span>
  );
};

export const selectedAll = (e, className = "item-list-check") => {
  if (e.target.checked) {
    const array = [];
    document.querySelectorAll(`.${className}`).forEach((elm) => {
      elm.checked = true;
      let _id = elm.value;
      array.push(_id);
    });
    return array;
  } else {
    document.querySelectorAll(".item-list-check").forEach((elm) => {
      elm.checked = false;
    });
    const array = [];
    return array;
  }
};

export const selectedOne = (e, className = "item-list-check") => {
  const elmClass = document.querySelectorAll(`.${className}`);
  if (e.target.value) {
    const array = [];
    elmClass.forEach((elm) => {
      if (elm.checked) {
        array.push(elm.value);
      }
    });
    return array;
  }
};

export const unselected = (
  id = "selected-all",
  className = "item-list-check"
) => {
  const elmClass = document.querySelectorAll(`.${className}`);
  document.querySelector(`#${id}`).checked = false;
  elmClass.forEach((elm) => {
    elm.checked = false;
  });
  const result = [];
  return result;
};
//set params
export const setParams = (key, val) => {
  const urlSearch = new URL(window.location.href)?.search;
  if (urlSearch.search(key) === -1) {
    const _symbol = urlSearch ? "&" : "";
    const _search = `${urlSearch}${_symbol}${key}=${val ? val : ""}`;
    return _search;
  } else {
    const params = new URLSearchParams(urlSearch);
    const oldValue = params.get(key);
    const newUrl = urlSearch.replace(
      `${key}=${oldValue}`,
      `${key}=${val ? val : ""}`
    );
    return newUrl;
  }
  return null;
};

export const userStatus = (item) => {
  if (item === "SUPER_ADMIN") {
    return "ບໍລິຫານສູງສຸດ";
  } else if (item === "ACCOUNTANT") {
    return "ບັນຊີ";
  } else if (item === "STATE_DIRECTOR") {
    return "ຫົວໜ້າບໍລິຫານສາຂາ";
  } else if (item === "BRANCH_DIRECTOR") {
    return "ຫົວໜ້າສາຂາ";
  } else if (item === "ADMIN") {
    return "ເອັດມິນ";
  } else if (item === "CALL_CENTER") {
    return "ພ/ງ ຕ້ອນຮັບ";
  } else {
    return "-";
  }
};

export const StatusService = (status) => {
  if (status === 0) {
    return "ຊຸດ";
  } else if (status === 2) {
    return "ຄັ້ງ";
  } else {
    return "-";
  }
};

export const paiStatus = (status) => {
  if (status === 1) {
    return "ຈ່າຍແລ້ວ";
  } else if (status === 0) {
    return "ຍັງບໍ່ທັນຈ່າຍ";
  } else {
    return "-";
  }
};

export const branchStatus = (status) => {
  if (status === "ACTIVE") {
    return "ເປີດນຳໃຊ້ບໍລິການ";
  } else if (status === "INACTIVE") {
    return "ປີດນຳໃຊ້ບໍລິການ";
  } else {
    return "-";
  }
};

// ຍອ້ອນຫລັງ 3  ເດືອ
export const startOfMonthThree = () => {
  return moment().subtract(3, "months").startOf("month").format("YYYY-MM-DD");
};
export const startEndMonthThree = () => {
  return moment().subtract(3, "months").endOf("month").format("YYYY-MM-DD");
};
// ຍອ້ອນຫລັງ 2  ເດືອ
export const startOfMonthTwo = () => {
  return moment().subtract(2, "months").startOf("month").format("YYYY-MM-DD");
};
export const startEndMonthTwo = () => {
  return moment().subtract(2, "months").endOf("month").format("YYYY-MM-DD");
};
// ຍອ້ອນຫລັງ 1  ເດືອ
export const startOfMonthOne = () => {
  return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
};
export const startEndMonthOne = () => {
  return moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");
};

//NO NUMBER
export const NO = ({ index, numberRow, numberPage }) => {
  const no = numberRow * numberPage - numberRow;
  if (numberRow > 0) {
    return currency(no + index + 1);
  } else {
    return currency(index + 1);
  }
};

// set Params
export const useSearchParams = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const customContentStateConverter = (contentState) => {
  // changes block type of images to 'atomic'
  const newBlockMap = contentState.getBlockMap().map((block) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey !== null) {
      const entityBlock = contentState.getEntity(entityKey);
      const entityType = entityBlock.getType();
      switch (entityType) {
        case "IMAGE": {
          const newBlock = block.merge({
            type: "atomic",
            text: "img",
          });
          return newBlock;
        }
        default:
          return block;
      }
    }
    return block;
  });
  const newContentState = contentState.set("blockMap", newBlockMap);
  return newContentState;
};

export const StatusCatalog = (item) => {
  if (item === "ACTIVITY") {
    return "ກິດຈະກຳ";
  } else if (item === "NEWS") {
    return "ແຈ້ງການ";
  } else if (item === "COMMUNITY") {
    return "ການຮຽນຮູ້ຕ່າງໆ";
  } else if (item === "PODCAST") {
    return "ແບ່ງປັນແນວຄິດ";
  } else if (item === "NOTICE") {
    return "ແຈ້ງການ";
  } else if (item === "REGISTER") {
    return "ລົງທະບຽນ";
  } else if (item === "PROMOTION") {
    return "ໂປຣໂມຊັ່ນ";
  } else if (item === "POST_APP") {
    return "ໂຄສະນາຝັ່ງແອັບ";
  } else if (item === "POST_WEB") {
    return "ໂຄສະນາຝັ່ງເວັບໄຊ";
  } else {
    return "-";
  }
};
export const StatusRegister = (item) => {
  if (item === "APPROVED") {
    return "ຮັບຟອມແລ້ວ";
  } else if (item === "REJECT") {
    return "ປະຕິເສດ";
  } else if (item === "REQUESTING") {
    return "ສະໝັກໃໝ່";
  } else {
    return "-";
  }
};

export const StatusPosition = (item) => {
  if (item === "CUSTOMER_SERVICE") {
    return "ບໍລິການລູກຄ້າ";
  } else if (item === "HR") {
    return "ບຸກຄະລະກອນ";
  } else if (item === "FINANCE") {
    return "ການເງິນ";
  } else if (item === "ACCOUNT") {
    return "ບັນຊີ";
  } else if (item === "LAW") {
    return "ກົດໝາຍ";
  } else if (item === "STATE_DIRECTOR") {
    return "ຫົວໜ້າບໍລິຫານສາຂາ";
  } else if (item === "BRANCH_DIRECTOR") {
    return "ຫົວໜ້າສາຂາ";
  } else if (item === "IT") {
    return "ໄອທິ";
  } else if (item === "ADMIN") {
    return "ແອັດມິນ";
  } else if (item === "DRIVER_DIRECTOR") {
    return "ຫົວໜ້າໂຊເເຟີ";
  } else if (item === "DRIVER") {
    return "ພະນັກງານຂັບລົດ";
  } else if (item === "AUDITION_DIRECTOR") {
    return "ຫົວໜ້າສູນຄັດແຍກ";
  } else if (item === "AUDITION_STAFF") {
    return "ພ/ງ ສູນຄັດແຍກ";
  } else if (item === "SHIPPER") {
    return "ໄລເດີ້";
  } else {
    return "-";
  }
};
