/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import useReactRouter from "use-react-router";
import introl from "../../img/introl2.png";
import qrcode from "../../img/IOS_ANDROID_DOWNLOAD.png";
import { QUERY_MEMBERS } from "../members/apollo";
import { currency, placeholderImage, url_path_images } from "../../helper";

export default function HomePage() {
  const { history, location, match } = useReactRouter();

  const [listMember, setListMember] = useState([]);
  const [allMember, setAllMember] = useState(0);
  const [totalGeneralMember, setTotalGeneralMember] = useState(0);
  const [totalVipMember, setTotalVipMember] = useState(0);
  const [totalShopMember, setTotalShopMember] = useState(0);
  const [fetchMemberData, { data: memberData }] = useLazyQuery(QUERY_MEMBERS, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    fetchMemberData({
      variables: {
        where: {
          isPublic: 1,
          isDeleted: 0,
        },
        limit: 100,
        isSummary: true,
      },
    });
  }, [fetchMemberData]);

  useEffect(() => {
    setListMember(memberData?.members?.data);
    setAllMember(memberData?.members?.total);
    setTotalGeneralMember(memberData?.members?.totalGeneralMember);
    setTotalVipMember(memberData?.members?.totalVipMember);
    setTotalShopMember(memberData?.members?.totalShop);
  }, [memberData]);

  return (
    <>
      <div style={{ backgroundImage: `url(${introl})`, height: 400,backgroundSize:'100%',backgroundPosition:'center' }}> </div>
      <div className="content-inner container-fluid" id="page_layout">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 mb-5 mb-md-0">
                    <div className="mb-5">
                      <div className="mb-2 d-flex justify-content-between align-items-center">
                        <span className="text-dark">ຈຳນວນທັງໝົດ</span>
                      </div>
                      <div className="mb-2">
                        <h2
                          className="counter"
                          style={{ visibility: "visible" }}
                        >
                          {allMember ? currency(allMember) : 0}
                        </h2>
                        <small>ຄົນ</small>
                      </div>
                    </div>
                    <div>
                      <div className="d-flex align-items-center gap-3 mb-3">
                        <div className="bg-soft-primary avatar-60 rounded">
                          <i className="icon-users fs-3" />
                        </div>
                        <div style={{ width: "100%" }}>
                          <div className="d-flex justify-content-between  ">
                            <h6>ສະມາຊິກທົ່ວໄປ</h6>
                            <h6 className="text-body">
                              {totalGeneralMember
                                ? currency(totalGeneralMember)
                                : 0}
                            </h6>
                          </div>
                          <div
                            className="progress bg-soft-primary shadow-none w-100"
                            style={{ height: 6 }}
                          >
                            <div
                              className="progress-bar bg-primary"
                              data-toggle="progress-bar"
                              role="progressbar"
                              aria-valuenow={totalGeneralMember}
                              aria-valuemin={0}
                              aria-valuemax={100}
                              style={{
                                width: `${totalGeneralMember}%`,
                                transition: "width 2s ease 0s",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-3 mt-4">
                        <div className="bg-soft-info avatar-60 rounded">
                          <i className="icon-users fs-3" />
                        </div>
                        <div style={{ width: "100%" }}>
                          <div className="d-flex justify-content-between  ">
                            <h6>ສະມາຊິກ VIP</h6>
                            <h6 className="text-body">
                              {totalVipMember ? currency(totalVipMember) : 0}
                            </h6>
                          </div>
                          <div
                            className="progress bg-soft-info shadow-none w-100"
                            style={{ height: 6 }}
                          >
                            <div
                              className="progress-bar bg-info"
                              data-toggle="progress-bar"
                              role="progressbar"
                              aria-valuenow={totalVipMember}
                              aria-valuemin={0}
                              aria-valuemax={100}
                              style={{
                                width: `${totalVipMember}%`,
                                transition: "width 2s ease 0s",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-3 mt-4">
                        <div className="bg-soft-success avatar-60 rounded">
                          <i className="icon-users fs-3" />
                        </div>
                        <div style={{ width: "100%" }}>
                          <div className="d-flex justify-content-between  ">
                            <h6>ຮ້ານອອນໄລນ໌</h6>
                            <h6 className="text-body">
                              {totalShopMember
                                ? currency(totalShopMember)
                                : 0}
                            </h6>
                          </div>
                          <div
                            className="progress bg-soft-success shadow-none w-100"
                            style={{ height: 6 }}
                          >
                            <div
                              className="progress-bar bg-success"
                              data-toggle="progress-bar"
                              role="progressbar"
                              aria-valuenow={totalShopMember}
                              aria-valuemin={0}
                              aria-valuemax={100}
                              style={{
                                width: `${totalShopMember}%`,
                                transition: "width 2s ease 0s",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="iq-scroller-effect">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <span className="text-dark">ສະມາຊິກທັງໝົດ</span>
                        <a
                          href="javascript:void(0);"
                          className="badge rounded-pill bg-soft-primary"
                          onClick={() => history.push("/members")}
                        >
                          ເບິ່ງທັງໝົດ
                        </a>
                      </div>
                      <div className="d-flex align-items-center iq-slider mb-4 gap-2">
                        {listMember?.map((item, index) => (
                          <div key={index}>
                            <img
                              className="rounded-circle bg-soft-primary img-fluid avatar-40 mb-2"
                              src={
                                item?.profileImage
                                  ? url_path_images + item?.profileImage
                                  : placeholderImage(60, 60)
                              }
                              alt="profile"
                              loading="lazy"
                            />
                          </div>
                        ))}
                      </div>
                      <center>
                      <img src={qrcode} alt="img" width={250} />
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
