import { gql } from "apollo-boost";

export const QUERY_USER = gql`
  query Users($where: UserWhereInput, $limit: Int, $skip: Int) {
    users(where: $where, limit: $limit, skip: $skip) {
      data {
        id
        profileImagePath
        email
        firstName
        lastName
        username
        phoneNumber
        password
        idCard
        role
        verifyCode
        isPublic
        isDeleted
        createdAt
        updatedAt
      }
      total
      totalActive
    }
  }
`;
export const CREATE_USERS = gql`
  mutation CreateUser($data: UserInput!) {
    createUser(data: $data) {
      data
      status
    }
  }
`;

export const UPDATE_USER = gql`
mutation UpdateUser($data: UserInput!, $where: WhereById!) {
  updateUser(data: $data, where: $where) {
    data
    status
  }
}
`;

export const DELETE_USER = gql`
  mutation DeleteUser($where: WhereById!) {
    deleteUser(where: $where) {
      data
      status
    }
  }
`;
