import { gql } from "apollo-boost";

export const QUERY_EMPLOYEES_VOTES = gql`
query SummaryPointOfStaff($where: WhereInputFilter) {
  summaryPointOfStaff(where: $where) {
    total
    data {
      TotalPoint
      fullName
      lastName
    }
  }
}
`;
