import { gql } from "apollo-boost";

export const QUERY_SLIDE_IMAGE = gql`
query SlideImages($where: SlideImageWhereInput, $limit: Int, $skip: Int) {
  slideImages(where: $where, limit: $limit, skip: $skip) {
    data {
      id
      image
      isPublic
      description
      type
      createdAt
      updatedAt
    }
    total
  }
}
`;
export const CREATE_SLIDE_IMAGE= gql`
mutation CreateSlideImage($data: SlideImageInput!) {
  createSlideImage(data: $data) {
    data
    status
  }
}
`;

export const UPDATE_SLIDE_IMAGE = gql`
mutation UpdateSlideImage($data: SlideImageInput!, $where: WhereById!) {
  updateSlideImage(data: $data, where: $where) {
    data
    status
  }
}
`;

export const DELETE_SLIDE_IMAGE = gql`
mutation DeleteSlideImage($where: WhereById!) {
  deleteSlideImage(where: $where) {
    data
    status
  }
}
`;
