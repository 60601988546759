/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import Pagination from "react-responsive-pagination";
import {
  currency,
  formatDateDash,
  ITEM_PER_PAGE,
  setParams,
  getCurrency,
  messageSuccess,
  messageError,
  url_path_video,
  placeholderImage,
} from "../../helper";

import "./app.scss";
import { DELETE_COMMUNITY, QUERY_COMMUNITY, UPDATE_COMMUNITY } from "./apollo";
import useReactRouter from "use-react-router";
import Notiflix from "notiflix";
import LimitData from "../../controllers/LimitData";
import AddVideo from "./addVideo";
import EditVideo from "./editVideo";

export default function VideoScreen() {
  const { history, location, match } = useReactRouter();
  const queryParams = new URLSearchParams(location?.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberRows, setNumberRows] = useState(ITEM_PER_PAGE);
  const [newLoadData, setNewLoadData] = useState(false);

  const [slideList, setCommunityList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [deleteCommunity] = useMutation(DELETE_COMMUNITY);
  const [updateCommunity] = useMutation(UPDATE_COMMUNITY);
  const [fetchCommunityData, { data: communityData }] = useLazyQuery(
    QUERY_COMMUNITY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    fetchCommunityData({
      variables: {
        where: {
          fullSearch: searchValue ? String(searchValue) : undefined,
          isVideo: 1,
        },
        skip: numberRows * currentPage - numberRows,
        limit: numberRows,
      },
    });
  }, [newLoadData, currentPage, numberRows, fetchCommunityData, searchValue]);

  useEffect(() => {
    setCommunityList(communityData?.communities?.data);
  }, [communityData]);

  const totalItem = communityData?.communities?.total;
  const totalPage = Math.ceil(totalItem / numberRows);

  const NO = (index) => {
    const no = numberRows * currentPage - numberRows;
    if (numberRows > 0) {
      return no + index + 1;
    } else {
      return index + 1;
    }
  };

  const _onChangeRows = (e) => {
    let _value = e?.target?.value;
    history.push({
      search: setParams(`rows`, _value),
    });
    setNumberRows(parseInt(_value));
  };

  useEffect(() => {
    let page = queryParams.get("page");
    let rows = queryParams.get("rows");

    if (page) {
      setCurrentPage(parseInt(page));
    } else {
      setCurrentPage(1);
    }
    if (rows) {
      setNumberRows(parseInt(rows));
    } else {
      setNumberRows(ITEM_PER_PAGE);
    }
  }, [queryParams]);

  //ລົບຂໍ້ມູນ
  const _handleDelete = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການລຶບຂໍ້ມູນ ນີ້ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _delete = await deleteCommunity({
            variables: {
              where: {
                id: parseInt(id),
              },
            },
          });
          if (_delete) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setNewLoadData(!newLoadData);
          }
        } catch (error) {
          console.log(error);
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  const _handleUpdate = (id, status) => {
    let _status = status === 1 ? 0 : 1;
    let _text = status === 1 ? "ປິດ" : "ເປີດໃຊ້ງານ";
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      `ທ່ານຕ້ອງການ${_text}ລາຍການນີ້ແທ້ ຫຼື ບໍ່?`,
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _updated = await updateCommunity({
            variables: {
              data: {
                isPublic: _status,
              },
              where: {
                id: parseInt(id),
              },
            },
          });
          if (_updated) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setNewLoadData(!newLoadData);
          }
        } catch (error) {
          console.log(error);
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <>
      <div className="content-inner pb-0 container-fluid" id="page_layout">
        <div className="row">
          <div className="col-md-12" style={{ zIndex: 1 }}>
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <p className="mb-md-0 mb-2 d-flex align-items-center gap-2">
                    <AddVideo
                      setNewLoad={(res) => setNewLoadData(!newLoadData)}
                    />
                  </p>
                  <div className="d-flex align-items-center flex-wrap">
                    <LimitData
                      numberRows={numberRows}
                      onChangeRows={_onChangeRows}
                      onSearch={(_onSearch) => {
                        setSearchValue(_onSearch);
                      }}
                      numberPage={currentPage}
                      total={totalItem}
                      hiddenSearch={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div id="cart" className="iq-product-tracker-card show b-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table className="table table-responsive mb-0 table-sm rounded">
                        <thead>
                          <tr>
                            <th width={10}>ລຳດັບ</th>
                            <th className="text-nowrap">ວີດີໂອ</th>
                            <th className="text-nowrap">ຫົວຂໍ້</th>
                            <th className="text-nowrap">ລາຍລະອຽດ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {slideList?.map((item, index) => (
                            <>
                              <tr data-item="list" key={index}>
                                <td className="text-center">{NO(index)}</td>
                                <td
                                  scope="row"
                                  className="text-nowrap"
                                  width={100}
                                >
                                  <div className="d-flex align-items-center gap-2 rounded">
                                    <video
                                    className="rounded"
                                      src={
                                        item?.videos
                                          ? url_path_video + item?.videos
                                          : placeholderImage(350, 220)
                                      }
                                      style={{
                                        height: 220,
                                        width: 320,
                                        borderRadius: 17,
                                      }}
                                      controls
                                    />
                                  </div>
                                </td>
                                <td>
                                  <h4>
                                    {" "}
                                    {item?.title ? item?.title : "Untitled"}
                                  </h4>
                                  <br />
                                  <span className="text-secondary">
                                    {item?.catalog?.title
                                      ? item?.catalog?.title
                                      : "unknown"}
                                  </span>
                                  <br />
                                  <span className="text-muted">
                                    <i className="icon-clock me-1" />{" "}
                                    {item?.createdAt
                                      ? formatDateDash(item?.createdAt)
                                      : "-"}
                                  </span>
                                  <hr/>
                                  <EditVideo
                                    item={item}
                                    onSuccess={() => {
                                      setNewLoadData(!newLoadData);
                                    }}
                                  />
                                  <button
                                    className="btn btn-icon  btn-danger rounded-circle"
                                    onClick={(e) => {
                                      _handleDelete(item?.id);
                                    }}
                                  >
                                    <i className="icon-trash fs-5" />
                                  </button>
                                  {item?.isPublic === 1 ? (
                                    <button
                                      onClick={() =>
                                        _handleUpdate(item?.id, item?.isPublic)
                                      }
                                      className="btn btn-icon btn-success ms-3 rounded-circle"
                                    >
                                      ເປີດ
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        _handleUpdate(item?.id, item?.isPublic)
                                      }
                                      className="btn btn-icon btn-danger ms-3 rounded-circle"
                                    >
                                      ປິດ
                                    </button>
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  <div>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item?.description
                                          ? item?.description
                                          : "No description",
                                      }}
                                    />
                                    <br />
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer">
                      {(() => {
                        return (
                          <Pagination
                            total={totalPage}
                            current={currentPage || 1}
                            onPageChange={(page) => {
                              history.push({
                                search: setParams("page", page.toString()),
                              });
                              setNumberRows(parseInt(page));
                            }}
                          />
                        );
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
