import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { getStaffLogin } from "../helper";
import logo from "../img/ans-logo.png";
import { MdScore } from "react-icons/md";
import { GrScorecard } from "react-icons/gr";
import { FaThList } from 'react-icons/fa';
export default function Sidenav({ location, history }) {
  const [activeStatus, setActiveStatus] = useState(
    location.pathname.split("/")[1]?.split("/")[0]
  );
  const [userData, setUserData] = useState({});
  useEffect(() => {
    const _local = getStaffLogin();
    setUserData(_local?.data || {});
    //sidebar min
    const localSideBarMini = localStorage.getItem("SIDEBAR_MINI");
    if (localSideBarMini === "true") {
      document.body.classList.add("mini-sidebar");
    }
  }, []);

  const [selectStatus, setSelectStatus] = useState(
    location.pathname.split("/")[2]?.split("/")[0]
  );

  const onRouting = (route, path) => {
    history.push(route ? route : location.pathname);
    setActiveStatus(route.split("/")[1]?.split("/")[0]);
    setSelectStatus(route.split("/")[2]?.split("/")[0]);
  };

  return (
    <aside
      className="sidebar sidebar-base main-sidebar mini-sidebar"
      id="first-tour"
      data-toggle="main-sidebar"
      data-sidebar="responsive"
    >
      <div className="sidebar-header d-flex align-items-center justify-content-start mb-5">
        <a
          href="javascript:;"
          onClick={() => history.push("/home")}
          className="navbar-brand"
        >
          {/*Logo start*/}
          <div className="logo-main text-center">
            <div className="logo-normal text-center">
              <img src={logo} className="img-fluid" style={{ width: 50 }} />
            </div>
          </div>
        </a>
        <div
          className="sidebar-toggle"
          data-toggle="sidebar"
          data-active="true"
        >
          <i className="icon">
            <svg
              className="icon-20"
              width={20}
              height={20}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.25 12.2744L19.25 12.2744"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </i>
        </div>
      </div>
      <div className="sidebar-body pt-0 data-scrollbar pb-5">
        <div className="sidebar-list">
          {/* Sidebar Menu Start */}
          <ul className="navbar-nav iq-main-menu pb-5" id="sidebar-menu">
            <li
              className={
                activeStatus && activeStatus === "home"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "home"
                    ? "nav-link active"
                    : "nav-link"
                }
                aria-current="page"
                onClick={() => onRouting("/home", "home")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ໜ້າຫຼັກ"
                  data-bs-placement="right"
                >
                  <svg
                    width={20}
                    className="icon-30"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z"
                      fill="currentColor"
                    />
                  </svg>
                </i>
                <span className="item-name">ໜ້າຫຼັກ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "customer-services"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "catalogs"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/catalogs", "catalogs")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ຈັດການໝວດໝູ່"
                  data-bs-placement="right"
                >
                  <svg
                    width={32}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M11.9912 18.6215L5.49945 21.864C5.00921 22.1302 4.39768 21.9525 4.12348 21.4643C4.0434 21.3108 4.00106 21.1402 4 20.9668V13.7087C4 14.4283 4.40573 14.8725 5.47299 15.37L11.9912 18.6215Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.89526 2H15.0695C17.7773 2 19.9735 3.06605 20 5.79337V20.9668C19.9989 21.1374 19.9565 21.3051 19.8765 21.4554C19.7479 21.7007 19.5259 21.8827 19.2615 21.9598C18.997 22.0368 18.7128 22.0023 18.4741 21.8641L11.9912 18.6215L5.47299 15.3701C4.40573 14.8726 4 14.4284 4 13.7088V5.79337C4 3.06605 6.19625 2 8.89526 2ZM8.22492 9.62227H15.7486C16.1822 9.62227 16.5336 9.26828 16.5336 8.83162C16.5336 8.39495 16.1822 8.04096 15.7486 8.04096H8.22492C7.79137 8.04096 7.43991 8.39495 7.43991 8.83162C7.43991 9.26828 7.79137 9.62227 8.22492 9.62227Z"
                      fill="currentColor"
                    />
                  </svg>
                </i>
                <span className="item-name">ຈັດການໝວດໝູ່</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "slide-images"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "slide-images"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/slide-images", "slide-images")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ຈັດການໂຄສະນາ"
                  data-bs-placement="right"
                >
                  <svg
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {" "}
                    <path
                      opacity="0.4"
                      d="M13.7505 9.70303V7.68318C13.354 7.68318 13.0251 7.36377 13.0251 6.97859V4.57356C13.0251 4.2532 12.764 4.00049 12.4352 4.00049H5.7911C3.70213 4.00049 2 5.653 2 7.68318V10.1155C2 10.3043 2.07737 10.4828 2.21277 10.6143C2.34816 10.7449 2.53191 10.8201 2.72534 10.8201C3.46035 10.8201 4.02128 11.3274 4.02128 11.9944C4.02128 12.6905 3.45068 13.2448 2.73501 13.2533C2.33849 13.2533 2 13.5257 2 13.9203V16.3262C2 18.3555 3.70213 19.9995 5.78143 19.9995H12.4352C12.764 19.9995 13.0251 19.745 13.0251 19.4265V17.3963C13.0251 17.0027 13.354 16.6917 13.7505 16.6917V14.8701C13.354 14.8701 13.0251 14.5497 13.0251 14.1655V10.4076C13.0251 10.0224 13.354 9.70303 13.7505 9.70303Z"
                      fill="currentColor"
                    ></path>{" "}
                    <path
                      d="M19.9787 11.9948C19.9787 12.69 20.559 13.2443 21.265 13.2537C21.6615 13.2537 22 13.5262 22 13.9113V16.3258C22 18.3559 20.3075 20 18.2186 20H15.0658C14.7466 20 14.4758 19.7454 14.4758 19.426V17.3967C14.4758 17.0022 14.1567 16.6921 13.7505 16.6921V14.8705C14.1567 14.8705 14.4758 14.5502 14.4758 14.1659V10.4081C14.4758 10.022 14.1567 9.70348 13.7505 9.70348V7.6827C14.1567 7.6827 14.4758 7.36328 14.4758 6.9781V4.57401C14.4758 4.25366 14.7466 4 15.0658 4H18.2186C20.3075 4 22 5.64406 22 7.6733V10.0407C22 10.2286 21.9226 10.4081 21.7872 10.5387C21.6518 10.6702 21.4681 10.7453 21.2747 10.7453C20.559 10.7453 19.9787 11.31 19.9787 11.9948Z"
                      fill="currentColor"
                    ></path>{" "}
                  </svg>
                </i>
                <span className="item-name">ຈັດການໂຄສະນາ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "community"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "community"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/community", "community")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ຈັດການກິດຈະກຳ"
                  data-bs-placement="right"
                >
                  <svg
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {" "}
                    <path
                      d="M10.1528 5.55559C10.2037 5.65925 10.2373 5.77027 10.2524 5.8844L10.5308 10.0243L10.669 12.1051C10.6705 12.3191 10.704 12.5317 10.7687 12.7361C10.9356 13.1326 11.3372 13.3846 11.7741 13.3671L18.4313 12.9316C18.7196 12.9269 18.998 13.0347 19.2052 13.2313C19.3779 13.3952 19.4894 13.6096 19.5246 13.8402L19.5364 13.9802C19.2609 17.795 16.4592 20.9767 12.6524 21.7981C8.84555 22.6194 4.94186 20.8844 3.06071 17.535C2.51839 16.5619 2.17965 15.4923 2.06438 14.389C2.01623 14.0624 1.99503 13.7326 2.00098 13.4026C1.99503 9.31279 4.90747 5.77702 8.98433 4.92463C9.47501 4.84822 9.95603 5.10798 10.1528 5.55559Z"
                      fill="currentColor"
                    ></path>{" "}
                    <path
                      opacity="0.4"
                      d="M12.8701 2.00082C17.43 2.11683 21.2624 5.39579 22.0001 9.81229L21.993 9.84488L21.9729 9.89227L21.9757 10.0224C21.9652 10.1947 21.8987 10.3605 21.784 10.4945C21.6646 10.634 21.5014 10.729 21.3217 10.7659L21.2121 10.7809L13.5313 11.2786C13.2758 11.3038 13.0214 11.2214 12.8314 11.052C12.6731 10.9107 12.5719 10.7201 12.5433 10.5147L12.0277 2.84506C12.0188 2.81913 12.0188 2.79102 12.0277 2.76508C12.0348 2.55367 12.1278 2.35384 12.2861 2.21023C12.4444 2.06662 12.6547 1.9912 12.8701 2.00082Z"
                      fill="currentColor"
                    ></path>{" "}
                  </svg>
                </i>
                <span className="item-name">ກິດຈະກຳ ແລະ ຂ່າວສານ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "videos"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "videos"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/videos", "videos")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ຈັດການວີດີໂອ"
                  data-bs-placement="right"
                >
                  <svg
                    width={32}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M21.3309 7.44251C20.9119 7.17855 20.3969 7.1552 19.9579 7.37855L18.4759 8.12677C17.9279 8.40291 17.5879 8.96129 17.5879 9.58261V15.4161C17.5879 16.0374 17.9279 16.5948 18.4759 16.873L19.9569 17.6202C20.1579 17.7237 20.3729 17.7735 20.5879 17.7735C20.8459 17.7735 21.1019 17.7004 21.3309 17.5572C21.7499 17.2943 21.9999 16.8384 21.9999 16.339V8.66179C21.9999 8.1623 21.7499 7.70646 21.3309 7.44251Z"
                      fill="currentColor"
                    />
                    <path
                      d="M11.9051 20H6.11304C3.69102 20 2 18.3299 2 15.9391V9.06091C2 6.66904 3.69102 5 6.11304 5H11.9051C14.3271 5 16.0181 6.66904 16.0181 9.06091V15.9391C16.0181 18.3299 14.3271 20 11.9051 20Z"
                      fill="currentColor"
                    />
                  </svg>
                </i>
                <span className="item-name">ຈັດການວີດີໂອ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "register"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "register"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/register", "register")}
              >
                <i
                  className="fa-solid fa-file fs-2 me-2"
                  style={{ color: "#d1cccc" }}
                ></i>
                <span className="item-name">ລາຍງານຟອມສະໝັກງານ</span>
              </a>
            </li>
            <li>
              <hr className="hr-horizontal" />
            </li>
            <li
              className={
                activeStatus && activeStatus === "members"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "members"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/members", "members")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ຈັດການສະມາຊິກ"
                  data-bs-placement="right"
                >
                  <svg
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {" "}
                    <path
                      d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
                      fill="currentColor"
                    ></path>{" "}
                    <path
                      opacity="0.4"
                      d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
                      fill="currentColor"
                    ></path>{" "}
                    <path
                      opacity="0.4"
                      d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z"
                      fill="currentColor"
                    ></path>{" "}
                    <path
                      d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
                      fill="currentColor"
                    ></path>{" "}
                    <path
                      opacity="0.4"
                      d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z"
                      fill="currentColor"
                    ></path>{" "}
                    <path
                      d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
                      fill="currentColor"
                    ></path>{" "}
                  </svg>
                </i>
                <span className="item-name">ຈັດການສະມາຊິກ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "users"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "users"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/users", "users")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ຈັດການທີມງານ"
                  data-bs-placement="right"
                >
                  <svg
                    class="icon-32"
                    width="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.34933 14.8577C5.38553 14.8577 2 15.47 2 17.9173C2 20.3665 5.364 20.9999 9.34933 20.9999C13.3131 20.9999 16.6987 20.3876 16.6987 17.9403C16.6987 15.4911 13.3347 14.8577 9.34933 14.8577Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M9.34935 12.5248C12.049 12.5248 14.2124 10.4062 14.2124 7.76241C14.2124 5.11865 12.049 3 9.34935 3C6.65072 3 4.48633 5.11865 4.48633 7.76241C4.48633 10.4062 6.65072 12.5248 9.34935 12.5248Z"
                      fill="currentColor"
                    ></path>
                    <path
                      opacity="0.4"
                      d="M16.1733 7.84873C16.1733 9.19505 15.7604 10.4513 15.0363 11.4948C14.961 11.6021 15.0275 11.7468 15.1586 11.7698C15.3406 11.7995 15.5275 11.8177 15.7183 11.8216C17.6165 11.8704 19.3201 10.6736 19.7907 8.87116C20.4884 6.19674 18.4414 3.79541 15.8338 3.79541C15.551 3.79541 15.2799 3.82416 15.0157 3.87686C14.9795 3.88453 14.9404 3.90177 14.9208 3.93244C14.8954 3.97172 14.914 4.02251 14.9394 4.05605C15.7232 5.13214 16.1733 6.44205 16.1733 7.84873Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M21.779 15.1693C21.4316 14.4439 20.593 13.9465 19.3171 13.7022C18.7153 13.5585 17.0852 13.3544 15.5695 13.3831C15.547 13.386 15.5343 13.4013 15.5324 13.4109C15.5294 13.4262 15.5363 13.4492 15.5656 13.4655C16.2662 13.8047 18.9737 15.2804 18.6332 18.3927C18.6185 18.5288 18.729 18.6438 18.867 18.6246C19.5333 18.5317 21.2476 18.1704 21.779 17.0474C22.0735 16.4533 22.0735 15.7634 21.779 15.1693Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </i>
                <span className="item-name">ຈັດການຂໍ້ມູນຜູ້ໃຊ້</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "report_feedback"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "report_feedback"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/report_feedback", "report_feedback")}
              >
                <i
                  className="icon ms-1"
                  data-bs-toggle="tooltip"
                  title="ລາຍງານການໂຫວດ"
                  data-bs-placement="right"
                >
                  <FaThList className="fs-3" />
                </i>
                <span className="item-name">ລາຍງານການໂຫວດ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "report_feedback"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "summary_score"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/summary_score", "summary_score")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ລາຍງານການໂຫວດ"
                  data-bs-placement="right"
                >
                <MdScore className="fs-2" />
                </i>
                <span className="item-name">ສະຫຼຸບຄະແນນການໂຫວດ</span>
              </a>
            </li>
            <li
              className={
                activeStatus && activeStatus === "report_feedback"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <a
                className={
                  activeStatus && activeStatus === "employee_score_votes"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => onRouting("/employee_score_votes", "/employee_score_votes")}
              >
                <i
                  className="icon"
                  data-bs-toggle="tooltip"
                  title="ສະຫຼຸບການໂຫວດພະນັກງານ"
                  data-bs-placement="right"
                >
                <GrScorecard className="fs-2"/>
                </i>
                <span className="item-name">ສະຫຼຸບການໂຫວດພະນັກງານ</span>
              </a>
            </li>
          </ul>
          {/* Sidebar Menu End */}
        </div>
      </div>
      <div className="sidebar-footer" />
    </aside>
  );
}
