import { gql } from "apollo-boost";

export const QUERY_CATALOGS = gql`
  query Catalogs($where: CatalogWhereInput, $limit: Int, $skip: Int) {
    catalogs(where: $where, limit: $limit, skip: $skip) {
      data {
        id
        coverImage
        title
        url
        type
        isPublic
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const CREATE_CATALOG = gql`
  mutation CreateCatalog($data: CatalogInput!) {
    createCatalog(data: $data) {
      data
      status
    }
  }
`;

export const UPDATE_CATALOG = gql`
  mutation UpdateCatalog($data: CatalogInput!, $where: WhereById!) {
    updateCatalog(data: $data, where: $where) {
      data
      status
    }
  }
`;

export const DELETE_CATALOG = gql`
  mutation DeleteCatalog($where: WhereById!) {
    deleteCatalog(where: $where) {
      data
      status
    }
  }
`;
