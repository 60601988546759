import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { placeholderImage, url_path_images } from ".";
export default function PreviewImage({ image, width, height }) {
  const [show, setShow] = useState(false);
  return (
    <React.Fragment>
      <div className="avatar sm">
        {image !== "null" ? (
          <>
            <a onClick={() => setShow(true)}>
              <img
                src={image ? url_path_images + image : placeholderImage(width, height)}
                title="ຄລິກເພື່ອເບິ່ງຮູບ"
                className="circle rounded"
                style={{ width: width, height: height, objectFit: "cover", cursor: "pointer" }}
              />
            </a>
          </>
        ) : (
          <>
            <div
              style={{
                backgroundImage: `url(${placeholderImage(100, 100)})`,
                display: "flex",
                height: 100,
                width: 100,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 7,
                backgroundSize: "100%",
              }}
            ></div>
          </>
        )}
      </div>
      <Modal show={show} centered scrollable size="lg" onHide={() => setShow(false)} className="text-center text-primary">
        <Modal.Header closeButton={() => setShow(false)}>
          <Modal.Title className="fs-5">
            <i className="icon-list" /> ເບິ່ງລາຍລະອຽດຮູບ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body text-center text-primary" style={{ backgroundColor: "transparent" }}>
          <img
            src={image ? url_path_images + image : placeholderImage(width, height)}
            className="img-fluid w-100"
            alt=""
            style={{ minHeight: height, minWidth: width }}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
